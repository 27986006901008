/* eslint-disable max-lines */
import { makeStyles } from '@material-ui/core';
import {
  COLOR1,
  COLOR3,
  COLOR4,
  GRAY44,
  FONT_BOLD_16_18,
  ORIENTAL_D_GRAY,
  RADIO_COLOR,
  COLOR2,
} from '../../constants';

export const CommonStyles = makeStyles(
  (theme) => ({
    ssnTooltip: {
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      [theme.breakpoints.between('1190', '1145')]: {
        marginLeft: '10px',
      },
    },
    ssnTextTooltip: {
      marginLeft: '3px',
      color: '#661C69',
      textAlign: 'center',
      fontFamily: 'TTNormsProBold !important',
      fontSize: '10px',
      lineHeight: '12px',
    },
    label_title: {
      marginBottom: '10px ',

      fontStyle: 'normal ',
      fontWeight: '700 ',
      fontSize: '14px',
      lineHeight: '18px ',
      color: '#444444 ',

      letterSpacing: '0.1px',
      position: 'relative',
      textAlign: 'left',
    },
    adorned_end: {
      padding: 0,
    },
    notchedOutline: {
      border: '1px solid #E4E9F2',
      borderRadius: '6px',
    },
    notchedOutlineErr: {
      border: `1px solid ${COLOR2}`,
      borderRadius: '6px',
    },
    outlined_inputs: {
      height: '48px',
      background: '#FFFFFF',
      border: '1px solid #E2E2E2',
      borderRadius: '6px',

      textAlign: 'left',
      color: COLOR1,
      font: 'normal normal 400 16px/18px Arial',
      letterSpacing: '0.1px',
      opacity: 1,
      '& .MuiOutlinedInput-input': {
        padding: '11.5px 14px',
      },
      // [`& fieldset`]: {
      //   borderRadius: '2px',
      //   border: `0.25px solid ${ORIENTAL_D_GRAY}`,
      // },
      '& ::-webkit-input-placeholder': {
        color: ORIENTAL_D_GRAY,
        opacity: 0.8,
      },
      '& ::-moz-placeholder': {
        color: ORIENTAL_D_GRAY,
        opacity: 0.8,
      },
      '& :-ms-input-placeholder': {
        color: ORIENTAL_D_GRAY,
        opacity: 0.8,
      },
      [theme.breakpoints.down('600')]: {
        '& ::-webkit-input-placeholder': {
          font: 'normal 400 16px Arial',
          color: '#808285',
        },
        '& ::-moz-placeholder': {
          font: 'normal 400 16px Arial',
          color: '#808285',
        },
        '& :-ms-input-placeholder': {
          font: 'normal 400 16px Arial',
          color: '#808285',
        },
      },
    },

    outlined_inputs_Mobile: {
      height: '48px',
      background: '#FFFFFF',
      border: '1px solid #E2E2E2',
      borderRadius: '6px',

      textAlign: 'left',
      color: COLOR1,
      font: 'normal normal 400 16px/18px Arial',
      letterSpacing: '0.1px',
      opacity: 1,
      // '& .MuiOutlinedInput-input': {
      //   padding: '11.5px 14px',
      // },
      // [`& fieldset`]: {
      //   borderRadius: '2px',
      //   border: `0.25px solid ${ORIENTAL_D_GRAY}`,
      // },
      '& ::-webkit-input-placeholder': {
        color: ORIENTAL_D_GRAY,
        opacity: 0.8,
      },
      '& ::-moz-placeholder': {
        color: ORIENTAL_D_GRAY,
        opacity: 0.8,
      },
      '& :-ms-input-placeholder': {
        color: ORIENTAL_D_GRAY,
        opacity: 0.8,
      },
      [theme.breakpoints.down('600')]: {
        '& ::-webkit-input-placeholder': {
          font: 'normal 400 16px Arial',
          color: '#808285',
        },
        '& ::-moz-placeholder': {
          font: 'normal 400 16px Arial',
          color: '#808285',
        },
        '& :-ms-input-placeholder': {
          font: 'normal 400 16px Arial',
          color: '#808285',
        },
      },
    },
    outlined_inputs_disabled: {
      border: '1px solid #E4E9F2 !important',
      background: '#F3F3F3 !important',
    },
    outlined_inputs_empStatus: {
      height: '48px',
      background: '#EEEEEE',
      border: '1px solid #E4E9F2',
      borderRadius: '6px',

      textAlign: 'left',
      color: COLOR1,
      font: 'normal normal 400 16px/18px Arial',
      letterSpacing: '0.1px',
      opacity: 1,
      '& .MuiOutlinedInput-input': {
        padding: '11.5px 14px',
      },
      '&.MuiInputBase-root.Mui-disabled': {
        border: 'none',
      },
      '& ::-webkit-input-placeholder': {
        color: ORIENTAL_D_GRAY,
        opacity: 0.8,
      },
      '& ::-moz-placeholder': {
        color: ORIENTAL_D_GRAY,
        opacity: 0.8,
      },
      '& :-ms-input-placeholder': {
        color: ORIENTAL_D_GRAY,
        opacity: 0.8,
      },
      [theme.breakpoints.down('600')]: {
        '& ::-webkit-input-placeholder': {
          font: 'normal 400 16px Arial',
          color: '#808285',
        },
        '& ::-moz-placeholder': {
          font: 'normal 400 16px Arial',
          color: '#808285',
        },
        '& :-ms-input-placeholder': {
          font: 'normal 400 16px Arial',
          color: '#808285',
        },
      },
    },
    saveButtonBox: {
      [theme.breakpoints.down('1038')]: {
        marginBottom: '30px',
      },
    },
    marginTopGrid: {
      marginTop: '40px',
      [theme.breakpoints.down('1038')]: {
        marginTop: '30px',
      },
    },
    button_grid: {
      flexWrap: 'nowrap',
      padding: '30px 0 20px 0',
      justifyContent: 'flex-end',
      textAlign: 'right',
      [theme.breakpoints.down('1038')]: {
        flexWrap: 'wrap',
      },
      [theme.breakpoints.down('600')]: {
        paddingTop: '30px',
        paddingBottom: '20px',
        justifyContent: 'center',
      },
    },
    back_button: {
      border: `1px solid ${RADIO_COLOR}`,
      borderRadius: '25px',
      opacity: 1,
      width: '165px',
      height: '45px',
      margin: '0px 15px 0px 0px',
      font: FONT_BOLD_16_18,
      // letterSpacing: '1.01px',
      color: `${RADIO_COLOR} !important`,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: COLOR4,
      },
      '&:focus': {
        outline: `2px solid ${RADIO_COLOR}`,
        outlineOffset: '2px',
      },
      [theme.breakpoints.down('600')]: {
        width: '100%',
      },
      // [theme.breakpoints.down('360')]: {
      //   width: '45%',
      // },
    },
    back_button_Consumer: {
      border: `1px solid ${RADIO_COLOR}`,
      borderRadius: '25px',
      opacity: 1,
      width: '213px',
      height: '48px',
      margin: '0px 15px 0px 0px',
      font: FONT_BOLD_16_18,
      // letterSpacing: '1.01px',
      color: `${RADIO_COLOR} !important`,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: COLOR4,
      },
      '&:focus': {
        outline: `2px solid ${RADIO_COLOR}`,
        outlineOffset: '2px',
      },
      [theme.breakpoints.down('600')]: {
        width: '100%',
      },
      // [theme.breakpoints.down('360')]: {
      //   width: '45%',
      // },
    },
    checkbox: {
      margin: '3px',
      '&:focus': {
        outline: `2px solid ${RADIO_COLOR}`,
        outlineOffset: '2px',
      },
    },
    continue_button: {
      border: `1px solid ${RADIO_COLOR}`,
      background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,
      borderRadius: '25px',
      opacity: 1,
      width: '165px',
      height: '45px',
      font: FONT_BOLD_16_18,
      textTransform: 'none',
      color: COLOR4,
      '&:hover': {
        backgroundColor: RADIO_COLOR,
      },
      '&:focus': {
        outline: `2px solid ${RADIO_COLOR}`,
        outlineOffset: '2px',
      },
      [theme.breakpoints.down('600')]: {
        width: '100%',
        marginBottom: '20px',
      },
      // [theme.breakpoints.down('360')]: {
      //   width: '45%',
      // },

      '&.Mui-disabled span': {
        opacity: 0.6,
      },
    },
    save_continue_button: {
      border: `1px solid ${RADIO_COLOR}`,
      background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,
      borderRadius: '25px',
      opacity: 1,
      width: '228px',
      height: '45px',
      font: FONT_BOLD_16_18,
      textTransform: 'none',
      letterSpacing: '1.01px',
      color: COLOR4,
      '&:hover': {
        backgroundColor: RADIO_COLOR,
      },
      '&:focus': {
        outline: `2px solid ${RADIO_COLOR}`,
        outlineOffset: '2px',
      },
      [theme.breakpoints.down('600')]: {
        width: '100%',
        marginBottom: '20px',
      },
      // [theme.breakpoints.down('360')]: {
      //   width: '45%',
      // },

      '&.Mui-disabled span': {
        opacity: 0.6,
      },
    },
    save_disabled_button: {
      '&.MuiButton-root.Mui-disabled': {
        background: `#E4E9F2`,
        border: `#E4E9F2`,
        borderRadius: '25px',
        color: 'rgba(143, 155, 179, 0.5)',
        width: '228px',
        height: '45px',
        font: 'normal normal bold 16px/18px Arial',
        letterSpacing: '1.01px',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#E4E9F2',
        },
        [theme.breakpoints.down('600')]: {
          width: '100%',
        },
      },
    },
    text_style_saveConti: {
      marginLeft: '10px',
      color: '#000000',
      font: 'normal 400 16px/normal Arial',
    },
    disabled_button: {
      '&.MuiButton-root.Mui-disabled': {
        background: `#F3F3F3`,
        border: `#F3F3F3`,
        borderRadius: '25px',
        color: '#D0D0D0',
        width: '165px',
        height: '45px',
        font: 'normal normal bold 16px/18px Arial',
        letterSpacing: '1.01px',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#F3F3F3',
        },
        [theme.breakpoints.down('600')]: {
          width: '100%',
        },
      },
    },
    allbuttonsBox: {
      borderRadius: '100px',
      background: '#F4EDF4',
      display: 'inline-flex',
      padding: '12px 30px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      color: '#661C69',
      textAlign: 'center',
      fontSize: '16px',
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '20px',
      // width: '260px',
      height: '48px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#F4EDF4',
      },
      [theme.breakpoints.down('600')]: {
        width: '100%',
        // marginBottom: '20px',
      },
    },
    contact_container: {
      padding: '10px 35px 10px 10px',
      background: `${COLOR3} 0% 0% no-repeat padding-box`,
      border: `0.25px solid ${GRAY44}`,
      borderRadius: '10px',
      opacity: 1,
      marginBottom: '20px',
      [theme.breakpoints.down('960')]: {
        padding: '20px 25px',
      },
    },
    first_screen_img: {
      [theme.breakpoints.down('960')]: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: 'none',
        marginTop: 0,
      },
      [theme.breakpoints.down('650')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    personalWrapper: {
      padding: '45px 35px',
      [theme.breakpoints.down('960')]: {
        padding: '20px 25px 25px 25px',
      },
    },
    personalPageWrapper: {
      paddingTop: '30px',
      paddingRight: '30px',
      paddingBottom: '30px',
      paddingLeft: '30px',
      [theme.breakpoints.down('600')]: {
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
      },
    },
    idDescriptionWrapper: {
      width: '80%',
      margin: '0 0 0 18%',
      [theme.breakpoints.down('960')]: {
        width: '100%',
        margin: '20px auto 0',
      },
      [theme.breakpoints.down('650')]: {
        marginTop: '25px',
        width: '100%',
      },
    },
    idDescriptionOne: {
      font: 'normal normal bold 18px/21px Arial',
      [theme.breakpoints.down('960')]: {
        font: 'normal normal bold 16px/17px Arial',
      },
    },
    IEPersonal_heading_info: {
      marginTop: 0,
      marginBottom: '25px',
      font: 'normal normal bold 20px/23px Arial',
      [theme.breakpoints.down('650')]: {
        marginTop: 0,
        marginBottom: '25px',
      },
    },
    ContinueBtnGrid: {
      [theme.breakpoints.down('600')]: {
        order: '1',
      },
    },
    backBtnGrid: {
      [theme.breakpoints.down('600')]: {
        order: '2',
      },
    },
    disabled_button_otp: {
      '&.MuiButton-root.Mui-disabled': {
        background: `#F3F3F3`,
        border: `#F3F3F3`,
        borderRadius: '56px',
        color: '#D0D0D0',
        width: '184px',
        height: '45px',
        font: 'normal normal bold 16px/18px Arial',
        letterSpacing: '1.01px',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#F3F3F3',
        },
        [theme.breakpoints.down('600')]: {
          width: '100%',
        },
      },
    },
    mobileButtons: {
      [theme.breakpoints.down('600')]: {
        width: '100%',
      },
    },
    label_Text: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#444444',
    },
    personal_button_container: {
      marginTop: '10px',
      justifyContent: 'center',
      display: 'flex',
    },
    cancel_next_button_container: {
      marginTop: '30px',
      justifyContent: 'center',
      display: 'flex',
    },
    back_button_otp: {
      border: `1px solid ${RADIO_COLOR}`,
      borderRadius: '56px',
      opacity: 1,
      width: '184px',
      height: '45px',
      margin: '0px 20px 0px 0px',
      font: FONT_BOLD_16_18,
      letterSpacing: '1.01px',
      color: `${RADIO_COLOR} !important`,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: COLOR4,
      },
      '&:focus': {
        outline: `2px solid ${RADIO_COLOR}`,
        outlineOffset: '2px',
      },
      [theme.breakpoints.down('600')]: {
        width: '100%',
        margin: '0px 0px 20px 0px',
      },
      // [theme.breakpoints.down('360')]: {
      //   width: '45%',
      // },
    },
    continue_button_otp: {
      border: `1px solid ${RADIO_COLOR}`,
      background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,
      borderRadius: '56px',
      opacity: 1,
      width: '184px',
      height: '45px',
      font: FONT_BOLD_16_18,
      textTransform: 'none',
      color: COLOR4,
      '&:hover': {
        backgroundColor: RADIO_COLOR,
      },
      '&:focus': {
        outline: `2px solid ${RADIO_COLOR}`,
        outlineOffset: '2px',
      },
      [theme.breakpoints.down('600')]: {
        width: '100%',
      },
      // [theme.breakpoints.down('360')]: {
      //   width: '45%',
      // },

      '&.Mui-disabled span': {
        opacity: 0.6,
      },
    },
    saveApplicationButton: {
      borderRadius: '100px',
      background: '#F4EDF4',
      display: 'inline-flex',
      padding: '12px 30px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      color: '#661C69',
      textAlign: 'center',
      fontSize: '16px',
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '20px',
      // width: '260px',
      height: '48px',
      textTransform: 'none',
      [theme.breakpoints.down('600')]: {
        width: '100%',
      },
      '&:focus': {
        outline: `2px solid ${RADIO_COLOR}`,
        outlineOffset: '2px',
      },
    },
    saveApplicationButtonDIS: {
      '&.MuiButton-root.Mui-disabled': {
        borderRadius: '100px',
        background: `#F3F3F3`,
        border: `#F3F3F3`,
        display: 'inline-flex',
        padding: '12px 30px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        color: 'rgba(143, 155, 179, 0.5)',
        textAlign: 'center',
        fontSize: '16px',
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '20px',
        // width: '260px',
        height: '48px',
        textTransform: 'none',
      },
      [theme.breakpoints.down('600')]: {
        width: '100%',
      },
    },
    next_continue_button: {
      border: `1px solid ${RADIO_COLOR}`,
      background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,
      borderRadius: '25px',
      opacity: 1,
      width: '242px',
      height: '48px',
      font: FONT_BOLD_16_18,
      fontWeight: 700,
      textTransform: 'none',
      color: COLOR4,
      '&:hover': {
        backgroundColor: RADIO_COLOR,
      },
      '&:focus': {
        outline: `2px solid ${RADIO_COLOR}`,
        outlineOff: `2px`,
      },
      [theme.breakpoints.down('600')]: {
        width: '100%',
        marginBottom: '20px',
      },
      // [theme.breakpoints.down('360')]: {
      //   width: '45%',
      // },

      '&.Mui-disabled span': {
        opacity: 0.6,
      },
    },
    next_disabled_button: {
      '&.MuiButton-root.Mui-disabled': {
        background: `#E4E9F2`,
        border: `#E4E9F2`,
        borderRadius: '25px',
        fontWeight: 700,
        color: 'rgba(143, 155, 179, 0.5)',
        width: '242px',
        height: '48px',
        font: 'normal normal bold 16px/18px Arial',
        letterSpacing: '1.01px',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#E4E9F2',
        },
        [theme.breakpoints.down('600')]: {
          width: '100%',
        },
      },
    },
    error_message_product: {
      font: 'normal normal normal 14px/16px Arial',
      letterSpacing: '0.1px',
      color: COLOR2,
      opacity: 1,
      marginTop: '4px',
      display: 'block',
    },
  }),
  { index: 1 }
);
