import { makeStyles } from '@material-ui/core';
import { COLOR4, RADIO_COLOR } from '../../constants';

export const SubmittedThankYouPageStyles = makeStyles(
  (theme) => ({
    main: {
      padding: '88px 30px 120px 100px',
      background: '#ffffff',
      [theme.breakpoints.between('600', '900')]: {
        padding: '50px',
      },
      [theme.breakpoints.down('600')]: {
        padding: '50px 30px',
      },
    },
    div: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('600')]: {
        flexDirection: 'column',
      },
    },
    container: {
      padding: '20px',
      background: '#f8f8f8',
      [theme.breakpoints.down('600')]: {
        padding: '10px',
      },
    },
    heading: {
      color: '#E37617',
      fontWeight: 700,
      fontSize: '64px',
      marginLeft: '26px',
      [theme.breakpoints.down('600')]: {
        fontSize: '56px',
        marginLeft: 0,
        textAlign: 'center',
      },
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '26px',
      [theme.breakpoints.down('600')]: {
        alignItems: 'flex-start',
      },
    },
    nextText: {
      color: '#E37617',
      fontSize: '35px',
      marginTop: '55px',
      fontWeight: 700,
      [theme.breakpoints.down('600')]: {
        marginTop: '39px',
        textAlign: 'center',
      },
    },
    successAppText: {
      fontSize: '32px',
      fontWeight: 700,
      fontFamily: 'Arial',
      marginTop: '15px',
      [theme.breakpoints.down('600')]: {
        textAlign: 'center',
      },
    },
    btnAlign: {
      [theme.breakpoints.down('600')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    text: {
      fontFamily: 'Arial',
      fontSize: '16px',
      fontWeight: 700,
      marginLeft: '25px',
    },
    button: {
      borderRadius: '56px',
      backgroundColor: '#661C69',
      width: '255px',
      marginTop: '38px',
      height: '48px',
      textTransform: 'none',
      justifyContent: 'center',
      color: '#FFFFFF',
    },
    thankyouButton: {
      background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,

      display: 'flex',
      color: COLOR4,
      justifyContent: 'center',
      letterSpacing: '0.3px',
      font: 'normal normal normal 16px/18px Arial',
      width: '250px',
      height: '45px',
      borderRadius: '25px',
      marginTop: '62px',
      marginBottom: '50px',
      opacity: 1,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: RADIO_COLOR,
      },
      '&:focus': {
        outline: `2px solid ${RADIO_COLOR}`,
        outlineOffset: '2px',
      },
      [theme.breakpoints.down('600')]: {
        padding: '0px 33px',
      },
      [theme.breakpoints.down('650')]: {
        marginTop: '50px',
        marginBottom: '50px',
      },
      [theme.breakpoints.down('320')]: {
        width: 'fit-content',
      },
    },
  }),
  { index: 1 }
);
