/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-lines */
import moment from 'moment';
import { Typography } from '@material-ui/core';

import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import './OtpLogin/ReceiveCode.css';
import {
  MANDATORY_FIELD_ERR,
  HOMEUS_PR,
  HOME_PR,
  HOME_VI,
  HOME_USA,
  HOMEUS_VI,
  SELF_EMPLOYED,
  EMPLOYED,
  INVALID_FORMAT_ERR,
  INVALID_DATE,
  PROGRESA_CONTIGO,
  ELITE_ACCOUNT_EN,
  PRODUCT_PERSONALCD,
  PRODUCT_PERSONAL,
} from '../constants';

import { businessMapping } from '../services/businessMapping';
import { fetchVouchedAddress, getAddressLookupData } from '../services/formService';
import { productMapping } from '../services/productMapping';
import { saveKnockOffData } from './DGI/CommonFunctions';
import { addAutoCompleteCityToDropdownIfMissing } from './DGI/DGIAccountInfoUtils';
import { dgiMapping } from '../services/dgiMapping';
import { productNameURLMapping } from '../services/productNameURLMapping';
import { dgiContMapping } from '../services/dgiContMapping';

export const commonTernary = (condition, result1, result2) => {
  if (condition) {
    return result1;
  }
  return result2;
};
export const commonFunction = (value) => {
  if (value) {
    return value;
  }
  return null;
};
export const zipCodeValueSetError = (zipcode) => {
  if (zipcode?.match(/^0*$/g)) {
    return '* Please enter a valid zipcode';
  }
  return '';
};
export const employedCommon = (e, leadGenerationContext, invalidMssg) => {
  if (e.target.value.length >= 2 && e.target.value.match(/^[A-Za-z0-9@&_.\-\s,()]*$/)) {
    leadGenerationContext.setEmploymentInformationErrors({
      ...leadGenerationContext.employmentInformationErrors,
      [e.target.name]: '',
    });
  } else if (e.target.value.length === 0) {
    leadGenerationContext.setEmploymentInformationErrors({
      ...leadGenerationContext.employmentInformationErrors,
      [e.target.name]: '* Mandatory Field',
    });
  } else {
    leadGenerationContext.setEmploymentInformationErrors({
      ...leadGenerationContext.employmentInformationErrors,
      [e.target.name]: invalidMssg,
    });
  }
};

export const handleETBFlow = (leadGenerationContext, history, nextPage) => {
  if (
    leadGenerationContext.customerType === 'ETB' &&
    leadGenerationContext.flowTypeETB === 'EtbFlow'
  ) {
    history.push(
      `/${leadGenerationContext.location?.toLowerCase()}/${leadGenerationContext.language?.toLowerCase()}/express/retail-form/${productNameURLMapping(
        leadGenerationContext.productName
      )}/${nextPage}`
    );
  }
};
export const isEtbFlow = (leadGenerationContext) =>
  (leadGenerationContext.customerType === 'ETB' &&
    leadGenerationContext.flowTypeETB === 'EtbFlow') ||
  false;

function areAllNumbersSameInPhoneNumber(phoneWithHyphens, leadGenerationContext) {
  let newPhoneNumber;
  const phoneWithoutHyphens = phoneWithHyphens.replace(/[-() ]/g, '');
  if (phoneWithoutHyphens.length === 7 && leadGenerationContext.location !== 'VI') {
    newPhoneNumber = phoneWithoutHyphens;
  } else if (phoneWithoutHyphens.length === 10 && leadGenerationContext.location === 'VI') {
    newPhoneNumber = phoneWithoutHyphens.substring(3);
  }
  if (newPhoneNumber && newPhoneNumber.length === 7) {
    const numbersArray = Array.from(newPhoneNumber);
    const firstNumber = numbersArray[0];
    return numbersArray.every((number) => number === firstNumber);
  }
  return false;
}

export const checkInvalidMobileNumber = (phoneNumber, leadGenerationContext) => {
  const result = areAllNumbersSameInPhoneNumber(phoneNumber, leadGenerationContext);
  return result;
};

export const autoCompleteOccupation = (name, value, leadGenerationContext) => {
  leadGenerationContext.setIsScreenFourDirty(true);
  leadGenerationContext.setIsScreenFiveDirty(true);
  leadGenerationContext.setIsFundingScreenDirty(true);
  leadGenerationContext.setEmploymentInformation({
    ...leadGenerationContext.employmentInformation,
    [name]: value,
  });
  if (value === null) {
    leadGenerationContext.setEmploymentInformationErrors({
      ...leadGenerationContext.employmentInformationErrors,
      [name]: '* Mandatory Field',
    });
  } else {
    leadGenerationContext.setEmploymentInformationErrors({
      ...leadGenerationContext.employmentInformationErrors,
      [name]: '',
    });
  }
};

export const autoCompleteCommon = (name, value, leadGenerationContext) => {
  leadGenerationContext.setIsTrustInfoDirty(true);
  leadGenerationContext.setIsFinanceInfoDirty(true);
  leadGenerationContext.setIsFundingScreenDirty(true);
  leadGenerationContext.setIsEdeliveryScreenDirty(true);

  leadGenerationContext.setDgiEmploymentStatus({
    ...leadGenerationContext.dgiEmploymentStatus,
    [name]: value,
  });
  if (value === null) {
    leadGenerationContext.setDgiEmploymentStatusErrors({
      ...leadGenerationContext.dgiEmploymentStatusErrors,
      [name]: MANDATORY_FIELD_ERR,
    });
  } else {
    leadGenerationContext.setDgiEmploymentStatusErrors({
      ...leadGenerationContext.dgiEmploymentStatusErrors,
      [name]: '',
    });
  }
};
export const autoCompleteCommonCommercial = (name, value, leadGenerationContext) => {
  leadGenerationContext.setIsBusinessAdditionalInfoDirty(true);
  leadGenerationContext.setIsBusinessSuriDirty(true);
  leadGenerationContext.setIsBusinessCompDirty(true);
  leadGenerationContext.setIsBusinessAccountInfoDirty(true);
  leadGenerationContext.setIsDocChecklistDirty(true);
  leadGenerationContext.setIsCommercialProductUIDirty(true);

  leadGenerationContext.setBusinessAdditionalInfo({
    ...leadGenerationContext.businessAdditionalInfo,
    [name]: value,
  });
  if (value === null) {
    leadGenerationContext.setBusinessAdditionalInfoErrors({
      ...leadGenerationContext.businessAdditionalInfoErrors,
      [name]: MANDATORY_FIELD_ERR,
    });
  } else {
    leadGenerationContext.setBusinessAdditionalInfoErrors({
      ...leadGenerationContext.businessAdditionalInfoErrors,
      [name]: '',
    });
  }
};
export const autoCompleteConCommon = (name, value, leadGenerationContext) => {
  leadGenerationContext.setConEmploymentStatus({
    ...leadGenerationContext.conEmploymentStatus,
    [name]: value,
  });
  if (value === null) {
    leadGenerationContext.setConEmploymentStatusError({
      ...leadGenerationContext.conEmploymentStatusError,
      [name]: MANDATORY_FIELD_ERR,
    });
  } else {
    leadGenerationContext.setConEmploymentStatusError({
      ...leadGenerationContext.conEmploymentStatusError,
      [name]: '',
    });
  }
};

export const empStatusAsEmpSelfEmp = (addressObject, leadGenerationContext) => {
  const { dgiEmploymentStatus } = leadGenerationContext;
  if (
    dgiEmploymentStatus.empStatus === SELF_EMPLOYED ||
    dgiEmploymentStatus.empStatus === EMPLOYED
  ) {
    leadGenerationContext.setIsTrustInfoDirty(true);
    leadGenerationContext.setIsFinanceInfoDirty(true);
    leadGenerationContext.setIsFundingScreenDirty(true);
    leadGenerationContext.setIsEdeliveryScreenDirty(true);
  } else if (leadGenerationContext.isCheckedTrustContact === true) {
    leadGenerationContext.setIsTrustInfoDirty(true);
    leadGenerationContext.setIsFinanceInfoDirty(true);
    leadGenerationContext.setIsFundingScreenDirty(true);
  }
};
const handleAddressInfo = (addressObject) => {
  let contryVal;
  if (addressObject?.Address?.CountrySubdivisionCode === HOMEUS_PR) {
    contryVal = HOME_PR;
  } else if (addressObject?.Address?.CountrySubdivisionCode === HOMEUS_VI) {
    contryVal = HOME_VI;
  } else {
    contryVal = HOME_USA;
  }
  return contryVal;
};
export const addressAutocomplete = (addressObject, leadGenerationContext) => {
  const { dgiEmploymentStatus, dgiEmploymentStatusErrors } = leadGenerationContext;

  empStatusAsEmpSelfEmp(addressObject, leadGenerationContext);

  let city = '';
  let state = '';
  let country = '';
  let zipCode = '';
  let address1 = '';
  if (
    addressObject?.Address?.AddressLine1 ||
    addressObject?.Address?.PostalCode ||
    addressObject?.Address?.City
  ) {
    address1 = commonFunction(addressObject?.Address?.AddressLine1);
    zipCode = addressObject?.Address?.PostalCode.split('-')[0];
    city = addressObject?.Address?.City;
  }
  country = handleAddressInfo(addressObject);

  if (
    (addressObject?.Address?.State && country === HOME_VI) ||
    (addressObject?.Address?.State && country === HOME_PR)
  ) {
    state = 'N/A';
  } else {
    state = addressObject?.Address?.State;
  }

  const cityPresent = addAutoCompleteCityToDropdownIfMissing(country, state, city);

  if (
    dgiEmploymentStatus.empStatus === SELF_EMPLOYED ||
    dgiEmploymentStatus.empStatus === EMPLOYED
  ) {
    if (!cityPresent) {
      leadGenerationContext.setDgiEmploymentStatus({
        ...dgiEmploymentStatus,
        missingAutoCompleteCity: city,
      });
    }
    leadGenerationContext.setDgiEmploymentStatus({
      ...dgiEmploymentStatus,
      address1,
      city,
      country,
      state,
      zipCode,
    });
    leadGenerationContext.setDgiEmploymentStatusErrors({
      ...dgiEmploymentStatusErrors,
      address1: '',
      city: '',
      country: '',
      state: '',
      zipCode: zipCodeValueSetError(zipCode),
    });
  }
};
export const addressAutocompleteTrusted = (addressObject, leadGenerationContext) => {
  let city = '';
  let state = '';
  let country = '';
  let zipCode = '';
  let address1 = '';
  if (
    addressObject?.Address?.AddressLine1 ||
    addressObject?.Address?.PostalCode ||
    addressObject?.Address?.City
  ) {
    address1 = commonFunction(addressObject?.Address?.AddressLine1);
    zipCode = addressObject?.Address?.PostalCode.split('-')[0];
    city = addressObject?.Address?.City;
  }
  country = handleAddressInfo(addressObject);

  if (
    (addressObject?.Address?.State && country === HOME_VI) ||
    (addressObject?.Address?.State && country === HOME_PR)
  ) {
    state = 'N/A';
  } else {
    state = addressObject?.Address?.State;
  }

  const cityPresent = addAutoCompleteCityToDropdownIfMissing(country, state, city);

  if (leadGenerationContext.isCheckedTrustContact === true) {
    if (!cityPresent) {
      leadGenerationContext.setTrustedContactInformation((prevState) => ({
        ...prevState,
        missingAutoCompleteCity: city,
      }));
    }

    leadGenerationContext.setTrustedContactInformation((prevState) => ({
      ...prevState,
      address1,
      city,
      country,
      state,
      zipCode,
    }));
    leadGenerationContext.setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      address1: '',
      city: '',
      country: '',
      state: '',
      zipCode: zipCodeValueSetError(zipCode),
    }));
  }
};

export async function fetchAddressLookup(
  address1,
  country,
  query,
  accessToken,
  setAddressLookupData,
  setAddressLookupDataResponse,
  setCallLookupApi,
  setCallLookupApiHome,
  setCallLookupApiEmp,
  setCallLookupApiHomeSelfEmp
) {
  if (address1?.length >= 6) {
    const response = await getAddressLookupData(
      country,
      query,
      accessToken,
      setCallLookupApi,
      setCallLookupApiHome,
      setCallLookupApiEmp,
      setCallLookupApiHomeSelfEmp
    );
    if (response && response.data && response.data.Results) {
      const options = [];
      response.data.Results.forEach((result) => {
        const address = result.Address;
        const zipCode = address.PostalCode.split('-')[0];
        options.push(`${address.AddressLine1}, ${address.City}, ${address.State}, ${zipCode}`);
      });
      setAddressLookupData(options);
    }
    setAddressLookupDataResponse(response);
  }
}
export const commonBusinessContactChange = (e, leadGenerationContext, reg) => {
  const {
    setBusinessContactInformation,
    setBusinessContactInformationErrors,
    setSaveAppContactInfo,
    setSaveAppContactInfoErrors,
  } = leadGenerationContext;
  if (e.target.value.match(reg)) {
    setBusinessContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setBusinessContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: '',
    }));
    if (e.target.name === 'lastName') {
      setSaveAppContactInfo((prevState) => ({
        ...prevState,
        lastName: e.target.value,
      }));
      setSaveAppContactInfoErrors((prevState) => ({ ...prevState, lastName: '' }));
    }
  } else if (e.target.value === '') {
    setBusinessContactInformation((prevState) => ({ ...prevState, [e.target.name]: '' }));
    setBusinessContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: '* Mandatory Field',
    }));
  } else {
    setBusinessContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setBusinessContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: '* Invalid Format',
    }));
  }
};

export const contactNameChange = (e, leadGenerationContext, regex) => {
  const { setContactInformation, setContactInformationErrors } = leadGenerationContext;
  if (e.target.value.match(regex)) {
    setContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setContactInformationErrors((prevState) => ({ ...prevState, [e.target.name]: '' }));
  } else if (e.target.value === '') {
    setContactInformation((prevState) => ({ ...prevState, [e.target.name]: '' }));
    setContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: MANDATORY_FIELD_ERR,
    }));
  } else {
    setContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: INVALID_FORMAT_ERR,
    }));
  }
};
export const saveAppcontactNameChange = (e, leadGenerationContext, regex) => {
  const {
    setSaveAppContactInfo,
    setSaveAppContactInfoErrors,
    setPersonalInformation,
    setPersonalInformationErrors,
    setContactInformation,
    setContactInformationErrors,
    setBusinessContactInformation,
    setBusinessContactInformationErrors,
  } = leadGenerationContext;
  if (e.target.value.match(regex)) {
    setSaveAppContactInfo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setSaveAppContactInfoErrors((prevState) => ({ ...prevState, [e.target.name]: '' }));

    setBusinessContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setBusinessContactInformationErrors((prevState) => ({ ...prevState, [e.target.name]: '' }));

    setPersonalInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setPersonalInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: '',
    }));
    setContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: '',
    }));
  } else if (e.target.value === '') {
    setSaveAppContactInfo((prevState) => ({ ...prevState, [e.target.name]: '' }));
    setSaveAppContactInfoErrors((prevState) => ({
      ...prevState,
      [e.target.name]: MANDATORY_FIELD_ERR,
    }));
  } else {
    setSaveAppContactInfo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setSaveAppContactInfoErrors((prevState) => ({
      ...prevState,
      [e.target.name]: INVALID_FORMAT_ERR,
    }));
  }
};
const timeOutFunc = (selectionStart, numkeys, e) => {
  setTimeout(() => {
    const input = document.getElementById('dgiAccountNumber');
    if (input?.setSelectionRange) {
      if (selectionStart + 1 === 4 && e.target.value.charAt(selectionStart - 1).includes(numkeys)) {
        input.setSelectionRange(selectionStart + 2, selectionStart + 2);
      } else if (selectionStart !== 4) {
        input.setSelectionRange(selectionStart, selectionStart);
      } else if (selectionStart === 4) {
        e.target.selectionEnd = selectionStart + 1;
      }
    }
  }, 10);
};
export const changeDgiAccNumberAndroid = (e, leadGenerationContext) => {
  const { setIsFundingScreenDirty, setFundData, setFundDataErrors, fundDataErrors, fundData } =
    leadGenerationContext;
  setIsFundingScreenDirty(true);
  const { selectionStart } = e.target;
  const alpha1 = Array.from(Array(26)).map((_e, i) => i + 65);
  const alphabet = alpha1.map((x) => String.fromCharCode(x));
  const smallAlpha = Array.from(Array(26)).map((_e, i) => i + 97);
  const smallAlphabet = smallAlpha.map((x) => String.fromCharCode(x));
  const nums = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const numkeys = [...nums, ...alphabet, ...smallAlphabet];

  let ssnValue = e.target.value;
  ssnValue = ssnValue.replace(/[^a-zA-Z0-9]/g, '');
  ssnValue = ssnValue.replace('-', '');
  if (e.target.value.length <= 10) {
    if (ssnValue?.toString().length >= 4) {
      ssnValue = `${ssnValue.slice(0, 3)}-${ssnValue.slice(3, ssnValue.toString().length)}`;
    }
    const alpha = ssnValue.slice(0, 3);
    if (alpha.match(/[N|n][E|e][E|c|e|C]/)) {
      setFundDataErrors({
        ...fundDataErrors,
        [e.target.name]: '',
      });
    } else {
      setFundDataErrors({
        ...fundDataErrors,
        [e.target.name]: '* Please enter a valid DGI account number',
      });
    }
    const num = ssnValue.slice(4, ssnValue.toString().length);
    if (!(num.match(/^\d+$/) && num.length === 6)) {
      setFundDataErrors({
        ...fundDataErrors,
        [e.target.name]: '* Please enter a valid DGI account number',
      });
    }
    timeOutFunc(selectionStart, numkeys, e);

    if (ssnValue === '') {
      setFundDataErrors({
        ...fundDataErrors,
        [e.target.name]: '* Mandatory Field',
      });
    }
    setFundData({ ...fundData, [e.target.name]: ssnValue });
  }
};

export const addressLineCheck = (address) => {
  let addrVal;
  if (address) {
    if (address.length <= 30) {
      addrVal = address;
    } else {
      addrVal = address.slice(0, 30);
    }
  } else {
    addrVal = '';
  }
  return addrVal;
};
export const clearOCRdata = (leadGenerationContext) => {
  const {
    setAddressProofUploadStatus,
    setAddressInformation,
    setAddressInformationErrors,
    addressInformation,
  } = leadGenerationContext;
  setAddressProofUploadStatus(null);
  setAddressInformation((prevState) => ({
    ...prevState,
    address1: '',
    address2: '',
    state: '',
    country: '',
    city: '',
    zipCode: '',
    vouchedAddrEdited: true,
  }));
  setAddressInformationErrors((prevState) => ({
    ...prevState,
    address2: '',
  }));
  if (addressInformation?.sameMailingAddress) {
    setAddressInformation((prevState) => ({
      ...prevState,
      permanentAddress1: '',
      permanentAddress2: '',
      permanentState: '',
      permanentCountry: '',
      permanentCity: '',
      permanentZipCode: '',
    }));
  }
};
export const handleAddressEditModal = (leadGenerationContext) => {
  const { setAddressEditQuestionModal, popupModalIntial } = leadGenerationContext;

  if (popupModalIntial) {
    setAddressEditQuestionModal(true);
  } else setAddressEditQuestionModal(false);
};

export const emailChange = (e, leadGenerationContext) => {
  const {
    setSaveAppContactInfo,
    setSaveAppContactInfoErrors,
    setPersonalInformation,
    setPersonalInformationErrors,
    setBusinessContactInformation,
    setBusinessContactInformationErrors,
  } = leadGenerationContext;
  if (
    e.target.value.match(
      /^([a-zA-Z0-9_.-]+)@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/
    )
  ) {
    const emailFirstPart = e.target.value.split('@');
    if (
      emailFirstPart[0].charAt(0) !== '.' &&
      emailFirstPart[0].charAt(0) !== '-' &&
      emailFirstPart[0].charAt(emailFirstPart[0].length - 1) !== '-' &&
      emailFirstPart[0].charAt(emailFirstPart[0].length - 1) !== '.' &&
      !e.target.value.match(/[.\-_]{2,}/)
    ) {
      setPersonalInformation((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      setSaveAppContactInfo((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      setSaveAppContactInfoErrors((prevState) => ({ ...prevState, [e.target.name]: '' }));
      setBusinessContactInformation((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      setBusinessContactInformationErrors((prevState) => ({ ...prevState, [e.target.name]: '' }));
      setPersonalInformationErrors((prevState) => ({ ...prevState, [e.target.name]: '' }));
    } else {
      setPersonalInformation((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      setPersonalInformationErrors((prevState) => ({
        ...prevState,
        [e.target.name]: INVALID_FORMAT_ERR,
      }));
      setSaveAppContactInfo((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      setSaveAppContactInfoErrors((prevState) => ({
        ...prevState,
        [e.target.name]: INVALID_FORMAT_ERR,
      }));
    }
  } else if (e.target.value === '') {
    setPersonalInformation((prevState) => ({ ...prevState, [e.target.name]: '' }));
    setPersonalInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: MANDATORY_FIELD_ERR,
    }));
    setSaveAppContactInfo((prevState) => ({ ...prevState, [e.target.name]: '' }));
    setSaveAppContactInfoErrors((prevState) => ({
      ...prevState,
      [e.target.name]: MANDATORY_FIELD_ERR,
    }));
  } else {
    setPersonalInformation((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    setPersonalInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: INVALID_FORMAT_ERR,
    }));
    setSaveAppContactInfo((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    setSaveAppContactInfoErrors((prevState) => ({
      ...prevState,
      [e.target.name]: INVALID_FORMAT_ERR,
    }));
  }
};
let dummyData = [];
const personalInfoDobChange = (personalInformation, saveAppContactInfo) => {
  if (personalInformation?.dob !== '') {
    if (personalInformation?.dob !== saveAppContactInfo?.dob) {
      if (!dummyData.includes('date of birth')) {
        dummyData.push('date of birth');
      }
    } else if (dummyData.includes('date of birth')) {
      const index = dummyData.indexOf('date of birth');
      dummyData.splice(index, 1);
    }
  }
};
export const handleCheckValuesSaveConti = (leadGenCont) => {
  const { personalInformation, saveAppContactInfo, vouchLastName } = leadGenCont;
  dummyData = [...leadGenCont.savelaterVal];
  personalInfoDobChange(personalInformation, saveAppContactInfo);
  if (vouchLastName !== '') {
    if (vouchLastName !== saveAppContactInfo?.lastName) {
      if (!dummyData.includes('last name')) {
        dummyData.push('last name');
      }
    } else if (dummyData.includes('last name')) {
      const index = dummyData.indexOf('last name');
      dummyData.splice(index, 1);
    }
  }
  if (personalInformation?.ssn !== '') {
    if (personalInformation?.ssn !== saveAppContactInfo?.ssn) {
      if (!dummyData.includes('ssn')) {
        dummyData.push('ssn');
      }
    } else if (dummyData.includes('ssn')) {
      const index = dummyData.indexOf('ssn');
      dummyData.splice(index, 1);
    }
  }

  return dummyData;
};

/**
 * Handle Save Application button: Should be navigated to Save Application Page */
export const handleSaveApplication = (e, leadGenerationContext) => {
  const { setIsModalSaveLater } = leadGenerationContext;
  e.preventDefault();
  setIsModalSaveLater(true);
};
export const clearUploadFocus = () => {
  let element = '';
  element = document.getElementById('uploadContainer');
  if (element) {
    element?.setAttribute(
      'style',
      'background-image: repeating-linear-gradient(0deg,#808285,#808285 7px,transparent 8px,transparent 15px),repeating-linear-gradient(90deg,#808285,#808285 7px,transparent 8px,transparent 15px),repeating-linear-gradient(180deg,#808285,#808285 7px,transparent 8px,transparent 15px),repeating-linear-gradient(270deg,#808285,#808285 7px,transparent 8px,transparent 15px) !important; background:#F4F4F4;background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;background-repeat: no-repeat;background-position: 0 0, 0 0, 100% 0, 0 100%'
    );
  }
};
export const getLang = (language, toLowerCase = false) => {
  let lang = 'En';
  if (language === 'ES') {
    lang = 'Es';
  }

  if (toLowerCase) return lang.toLowerCase();

  return lang;
};
export const nameProduct = (data) => {
  let setProduct;
  if (dgiMapping(data.productName)) {
    setProduct = dgiMapping(data.productName);
  } else if (dgiContMapping(data.productName)) {
    setProduct = dgiContMapping(data.productName);
  } else {
    setProduct = productMapping(data.productName);
  }
  return setProduct;
};
export const handleDOBchange = (newValue, leadGenerationContext) => {
  const {
    setPersonalInformation,
    personalInformation,
    setPersonalInformationErrors,
    personalInformationErrors,
    setBusinessAccountInfo,
    setBusinessAccountInfoError,
    businessAccountInfo,
    businessAccountInfoError,
    productName,
  } = leadGenerationContext;

  if (businessMapping(productName)) {
    leadGenerationContext.setIsBusinessAccountInfoDirty(true);
    leadGenerationContext.setIsDocChecklistDirty(true);
    leadGenerationContext.setIsCommercialProductUIDirty(true);
    if (newValue) {
      const selectedDate = new Date(newValue);
      const currentdate = new Date();
      const formattedDate = moment(newValue).format('yyyy-MM-DD');

      setBusinessAccountInfo({ ...businessAccountInfo, dob: formattedDate });
      if (newValue == 'Invalid Date') {
        setBusinessAccountInfo({ ...businessAccountInfo, dob: formattedDate });
        setBusinessAccountInfoError({
          ...businessAccountInfoError,
          dob: INVALID_DATE,
        });
      } else if (selectedDate.getTime() > currentdate.getTime()) {
        setBusinessAccountInfoError({
          ...businessAccountInfoError,
          dob: INVALID_DATE,
        });
      } else {
        setBusinessAccountInfoError({ ...businessAccountInfoError, dob: '' });
      }
    } else {
      setBusinessAccountInfo({ ...businessAccountInfo, dob: '' });
      setBusinessAccountInfoError({
        ...businessAccountInfoError,
        dob: '* Mandatory Field',
      });
    }
  } else if (productMapping(productName) || dgiMapping(productName)) {
    if (newValue) {
      const selectedDate = new Date(newValue);
      const currentdate = new Date();
      const formattedDate = moment(newValue).format('yyyy-MM-DD');
      setPersonalInformation({ ...personalInformation, dob: formattedDate });
      if (newValue == 'Invalid Date') {
        setPersonalInformation({ ...personalInformation, dob: formattedDate });
        setPersonalInformationErrors({
          ...personalInformationErrors,
          dob: INVALID_DATE,
        });
      } else if (selectedDate.getTime() > currentdate.getTime()) {
        setPersonalInformationErrors({
          ...personalInformationErrors,
          dob: INVALID_DATE,
        });
      } else {
        setPersonalInformationErrors({ ...personalInformationErrors, dob: '' });
      }
    } else {
      setPersonalInformation({ ...personalInformation, dob: '' });
      setPersonalInformationErrors({
        ...personalInformationErrors,
        dob: MANDATORY_FIELD_ERR,
      });
    }
  }
};

export const getNameInUpperCase = (name) => {
  if (name?.includes(' ')) {
    const words = name.toLowerCase().split(' ');
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};
function cryptoRandom(len) {
  const uuid = uuidv4().replace(/-/g, '');
  return uuid.slice(0, len);
}
export const generateKey = () => cryptoRandom(32);

export const encryptData = (str, code) => {
  const prefix = cryptoRandom(16);
  const iv = CryptoJS.enc.Utf8.parse(code.substring(0, 16));
  const key = CryptoJS.enc.Utf8.parse(code);

  return AES.encrypt(prefix + str, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
};

export const decryptData = (str, code) => {
  const iv = CryptoJS.enc.Utf8.parse(code.substring(0, 16));
  const key = CryptoJS.enc.Utf8.parse(code);

  return AES.decrypt(str, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
    .toString(CryptoJS.enc.Utf8)
    .slice(16);
};
export const languageDone = (language) => {
  const { t } = useTranslation();
  if (language === 'EN') {
    return (
      <>
        {' '}
        <Typography variant="h2" className="welcomeTextR">
          {t('Welcome to ', {
            defaultValue: `Welcome to `,
          })}
          <span style={{ color: '#f5821f' }}>
            {t('O', {
              defaultValue: `O`,
            })}
          </span>
          {t('riental', {
            defaultValue: `riental`,
          })}
        </Typography>
      </>
    );
  }
  return (
    <Typography variant="h2" className="welcomeTextR">
      {t('Bienvenido a ', {
        defaultValue: `Bienvenido a `,
      })}
      <span style={{ color: '#f5821f' }}>
        {t('O', {
          defaultValue: `O`,
        })}
      </span>
      {t('riental', {
        defaultValue: `riental`,
      })}
    </Typography>
  );
};

export const handleDOBchangeSaveAppContact = (newValue, context) => {
  const {
    setSaveAppContactScreenDirty,
    setSaveAppContactInfo,
    saveAppContactInfo,
    setBusinessContactInformation,
    setPersonalInformation,
    personalInformation,
    setBusinessAccountInfo,
    businessAccountInfo,
    setSaveAppContactInfoErrors,
    saveAppContactInfoErrors,
    setBusinessAccountInfoError,
    businessAccountInfoError,
    setPersonalInformationErrors,
    personalInformationErrors,
    setBusinessContactInformationErrors,
  } = context;
  setSaveAppContactScreenDirty(true);
  if (newValue) {
    const selectedDate = new Date(newValue);
    const currentdate = new Date();
    const formattedDate = moment(newValue).format('yyyy-MM-DD');

    setSaveAppContactInfo({ ...saveAppContactInfo, dob: formattedDate });
    if (newValue != 'Invalid Date') {
      setBusinessContactInformation((prevState) => ({
        ...prevState,
        dob: formattedDate,
      }));
      setPersonalInformation({ ...personalInformation, dob: formattedDate });
      setBusinessAccountInfo({ ...businessAccountInfo, dob: formattedDate });
    }
    if (newValue == 'Invalid Date') {
      setSaveAppContactInfo({ ...saveAppContactInfo, dob: formattedDate });
      setSaveAppContactInfoErrors({
        ...saveAppContactInfoErrors,
        dob: INVALID_DATE,
      });
      setBusinessAccountInfo({ ...businessAccountInfo, dob: formattedDate });
      setBusinessAccountInfoError({
        ...businessAccountInfoError,
        dob: INVALID_DATE,
      });
    } else if (selectedDate.getTime() > currentdate.getTime()) {
      setSaveAppContactInfoErrors({
        ...saveAppContactInfoErrors,
        dob: INVALID_DATE,
      });
    } else {
      setSaveAppContactInfoErrors({ ...saveAppContactInfoErrors, dob: '' });
      setPersonalInformationErrors({ ...personalInformationErrors, dob: '' });
      setBusinessContactInformationErrors((prevState) => ({
        ...prevState,
        dob: '',
      }));
      setBusinessAccountInfoError({
        ...businessAccountInfoError,
        dob: '',
      });
    }
  } else {
    setSaveAppContactInfo({ ...saveAppContactInfo, dob: '' });
    setSaveAppContactInfoErrors({
      ...saveAppContactInfoErrors,
      dob: MANDATORY_FIELD_ERR,
    });
    setBusinessAccountInfoError({
      ...businessAccountInfoError,
      dob: MANDATORY_FIELD_ERR,
    });
  }
};
export const vouchedJobCallForFraudCheck = async (setIsValidating, leadGenerationContext) => {
  const {
    vouchedJob,
    accessToken,
    setVouchedResponseAddr,
    setPersonalInformation,
    setBusinessAccountInfo,
    setDisableContinue,
    setVouchedFailModal,
  } = leadGenerationContext;

  setIsValidating(true);
  const response = await fetchVouchedAddress(vouchedJob, accessToken);
  const vouchedResponse = response?.data?.items[0];
  setVouchedResponseAddr(vouchedResponse);
  if (vouchedResponse?.result?.success === false) {
    setIsValidating(false);
    setPersonalInformation((prevState) => ({ ...prevState, uploadSuccessStatus: null }));
    setBusinessAccountInfo((prevState) => ({
      ...prevState,
      uploadSuccessStatus: null,
    }));
    setDisableContinue(true);
    setVouchedFailModal(true);
    const ocrErrorList = [];
    let ocrErrorListinString = '';
    for (const x of vouchedResponse.errors) {
      ocrErrorList.push(x.message);
      ocrErrorListinString = ocrErrorList.join();
    }
    if (leadGenerationContext.productName !== PROGRESA_CONTIGO) {
      await saveKnockOffData(leadGenerationContext, ocrErrorListinString, vouchedJob, '');
    }
  } else {
    setIsValidating(false);
  }
};
export const isPlaidProduct = (prod) =>
  commonTernary(
    productMapping(prod) === ELITE_ACCOUNT_EN ||
      productMapping(prod) === 'Ahorra' ||
      isCDproduct(prod),
    true,
    false
  );
export const isCDproduct = (prod) =>
  commonTernary(
    productMapping(prod) === PRODUCT_PERSONAL,
    //  || productMapping(prod) === PRODUCT_PERSONALCD
    true,
    false
  );
export const formatDepositAmount = (amount, context) => {
  const { setCdFlowOffers, cdFlowOffers } = context;
  if (amount.endsWith('.00')) {
    const valueWithoutDecimal = amount.slice(0, -3);
    const valueWithoutComma = valueWithoutDecimal.replace(/,/g, '');
    setCdFlowOffers({ ...cdFlowOffers, depositAmount: valueWithoutComma });
    return valueWithoutComma;
  }
};
export const isKnockOffValueEmployer = async (searchValue) => {
  const list = [
    'Creator Alliance',
    'Creator Alliance LLC',
    'Creator Alliance Puerto Rico',
    'Creators Alliance',
    'Creators Alliance LLC',
    'Creators Alliance Puerto Rico',
    'Creator Aliance',
    'Creator Aliance LLC',
    'Creator Aliance Puerto Rico',
  ];

  return list.some((x) => x.toLowerCase() === searchValue.toLowerCase());
};
