import { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { footerStyles } from './FooterStyles';
import { FOOTER_MSG, FOOTER_KEY } from '../../constants';
import { productTypeMapping } from '../../services/productTypeMapping';
import LeadGenerationContext from '../../context/LeadGenerationContext';

const useStyles = footerStyles();
const Footer = () => {
  const { t } = useTranslation();
  const styledClasses = useStyles();
  const { productType } = useContext(LeadGenerationContext);
  const currentYear = '2025';
  return (
    <div className={styledClasses.footer_position}>
      <Grid container className={styledClasses.footer}>
        <div tabIndex="-1" className={styledClasses.copyrights_text} title="footerDescription">
          {`© ${currentYear} `}
          {productTypeMapping(productType) === 'Credit'
            ? t('Oriental. All Rights Reserved.', {
                defaultValue: 'Oriental. All Rights Reserved.',
              })
            : t(FOOTER_KEY, {
                defaultValue: FOOTER_MSG,
              })}
        </div>
      </Grid>
    </div>
  );
};

export default Footer;
