/* eslint-disable no-await-in-loop */
import axios from 'axios';
import JsZip from 'jszip';
import FileSaver from 'file-saver';
import { createFormData } from './formServiceUtils';

const exportFilesAsZip = (blobs) => {
  const zip = JsZip();
  blobs.forEach((blob) => {
    const fileType = blob.type.split('/')[1];
    zip.file(`${blob.fileName}.${fileType}`, blob);
  });
  zip.generateAsync({ type: 'blob' }).then((zipFile) => {
    const fileName = `Consents.zip`;
    return FileSaver.saveAs(zipFile, fileName);
  });
};
const linkedConsentDetails = [];

const linkedConsentsCall = async (consent, accessToken) => {
  let internalLink;
  if (consent?.linkedConsents) {
    const { linkedConsents } = consent;
    for (const item of linkedConsents) {
      const linkedConsent = item;
      if (linkedConsent.locationType !== 'Internal') {
        if (linkedConsent.name === 'E-Sign Disclosure') {
          internalLink = await axios.get(
            `${process.env.REACT_APP_PRE_SIGNED_URL}${linkedConsent.s3Location}`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          if (internalLink) {
            linkedConsentDetails.push({
              url: internalLink.data.preSignedUrl,
              name: linkedConsent?.name,
            });
          }
        } else {
          linkedConsentDetails.push({
            url: linkedConsent?.location,
            name: linkedConsent?.name,
          });
        }
      } else {
        internalLink = await axios.get(
          `${process.env.REACT_APP_PRE_SIGNED_URL}${linkedConsent.location}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        if (internalLink) {
          linkedConsentDetails.push({
            url: internalLink.data.preSignedUrl,
            name: linkedConsent?.name,
          });
        }
      }
    }
  }
};
export const downloadConsentFiles = async (consents, accessToken) => {
  for (const element of consents) {
    const consent = element;
    await linkedConsentsCall(consent, accessToken);
  }
  const responseBlobs = [];
  for (const consentItem of linkedConsentDetails) {
    const linkConsent = consentItem;
    const { url } = linkConsent;
    const resp = await axios.get(url, {
      responseType: 'blob',
    });
    resp.data.fileName = linkConsent.name;
    responseBlobs.push(resp.data);
  }
  exportFilesAsZip(responseBlobs);
};

export const getProductConsents = async (
  data,
  accessToken,
  customerType,
  flowTypeETB,
  customerAppId,
  sessionCallFlagConsent,
  setSessionCallFlagConsent
) => {
  const formData = createFormData(['ProductConsents'], data);
  let response = {};
  try {
    if (customerType === 'ETB' && flowTypeETB === 'EtbFlow') {
      if (sessionCallFlagConsent) {
        response = await axios.post(process.env.REACT_APP_AGE_RULE_URL_EXPRESS, formData, {
          headers: {
            'Application-Id': customerAppId,
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json; charset=UTF-8',
          },
        });
        if (response && response.status === 200) {
          setSessionCallFlagConsent(false);
        }
      }
    } else {
      response = await axios.post(process.env.REACT_APP_AGE_RULE_URL, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
    }
  } catch (e) {
    return response;
  }
  return response;
};
const getInternalLink = async (linkedConsent, languageTranslation, accessToken, matchedValuesj) => {
  let htmlLink1 = '';
  if (linkedConsent?.locationType !== 'Internal') {
    htmlLink1 = `<a href="${linkedConsent?.location}" id="${linkedConsent?.name}" class="consent-link">${languageTranslation[matchedValuesj]}</a>`;
  } else {
    const internalLink = await axios.get(
      `${process.env.REACT_APP_PRE_SIGNED_URL}${linkedConsent?.location}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    if (internalLink) {
      htmlLink1 = `<a href="${internalLink.data.preSignedUrl}" id="${linkedConsent?.name}" class="consent-link">${languageTranslation[matchedValuesj]}</a>`;
    }
  }
  return htmlLink1;
};
export const getProductConsentPromises = async (
  consents,
  accessToken,
  languageTranslation,
  customerType,
  flowTypeETB,
  customerAppId
) => {
  const promiseArray = [];
  for (const element of consents) {
    const consent = element;
    let consentText = null;
    if (customerType === 'ETB' && flowTypeETB === 'EtbFlow') {
      consentText = await axios.get(
        `${process.env.REACT_APP_GET_CONSENT_URL_EXPRESS}${consent.location}`,
        {
          headers: {
            'Application-Id': customerAppId,
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'text/html',
          },
        }
      );
    } else {
      consentText = await axios.get(`${process.env.REACT_APP_GET_CONSENT_URL}${consent.location}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'text/html',
        },
      });
    }
    let changedConsentText = consentText.data;
    let unChangedConsentText = consentText.data;
    if (consent?.linkedConsents) {
      const matches = consentText.data.match(/\{\{(.*?)\}\}/gm);
      const matchedValues = consentText.data.match(/([^{]+(?=}}))/g);
      const { linkedConsents } = consent;
      for (let j = 0; j < matchedValues.length; j++) {
        const linkedConsent = linkedConsents.find((item) => item.name === matchedValues[j]);
        const htmlLink = await getInternalLink(
          linkedConsent,
          languageTranslation,
          accessToken,
          matchedValues[j]
        );

        changedConsentText = changedConsentText.replace(matches[j], htmlLink);

        unChangedConsentText = unChangedConsentText.replace(
          matches[j],
          languageTranslation[matchedValues[j]]
        );
      }
    }
    consent.consentText = changedConsentText;
    consent.unChangedConsentText = unChangedConsentText;
    promiseArray.push(consent);
  }
  return promiseArray;
};

export const getConsentTermsAndConditions = async (applicationId, accessToken) => {
  let result = null;
  try {
    result = await axios.get(`${process.env.REACT_APP_CD_TERMS_AND_CONDITIONS}${applicationId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};
export const getConsentTermsAndConditionsText = async (accessToken) => {
  let result = null;
  try {
    result = await axios.get(`${process.env.REACT_APP_CD_TERMS_AND_CONDITIONS_TEXT}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};
export const getConsentTermsAndConditionsTextES = async (accessToken) => {
  let result = null;
  try {
    result = await axios.get(`${process.env.REACT_APP_CD_TERMS_AND_CONDITIONS_TEXT_ES}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};

export const automatedFlowStatus = async (
  data,
  accessToken,
  customerType,
  flowTypeETB,
  customerAppId
) => {
  let response = null;
  try {
    if (customerType === 'ETB' && flowTypeETB === 'EtbFlow') {
      response = await axios.post(process.env.REACT_APP_AUTOMATED_FLOW_URL_EXPRESS, data, {
        headers: {
          'Application-Id': customerAppId,
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } else {
      response = await axios.post(process.env.REACT_APP_AUTOMATED_FLOW_URL, data, {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
  } catch (e) {
    return response;
  }
  return response;
};
