/* eslint-disable max-lines */
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ProductHeading } from './LeadGeneration/ProductHeading/ProductHeading';
import StepperWizard from './Wizard/StepperWizard';
import LeadGenerationContext from '../context/LeadGenerationContext';
import { CommonStyles } from './LeadGeneration/CommonStyles';
import {
  BACK,
  BUSINESS_COMPLIANCE_INFO,
  COLOR1,
  CONTINUE,
  PERSONAL_INFO,
  PROGRESA_CONTIGO,
  SAVEYOURAPPLICATION,
} from '../constants';
import Loader from './Loader/Loader';
import { ProductHeadingStyles } from './LeadGeneration/ProductHeading/ProductHeadingStyles';
import { commonTernary, isCDproduct } from './commonCode';
import BookmarkIcon from '../images/BookmarkIcon.png';
import BookmarkIconDisable from '../images/disableiconsavebtn.svg';
import SaveContinueIcon from '../images/saveContinueIcon.svg';
import { productMapping } from '../services/productMapping';
import { dgiMapping } from '../services/dgiMapping';
import SimpleTextModal from './Modal/SimpleTextModal';
import SnackbarCloseIcon from '../images/Close.svg';
import snackbarInfo from '../images/info-return.svg';
import { ReturnToSavedApplicationStyles } from './SaveAndContinue/ReturnToSavedApplicationStyles';
import { businessMapping } from '../services/businessMapping';

// eslint-disable-next-line react/prop-types
const Layout = (props) => {
  const commonClasses = ProductHeadingStyles();

  const {
    children,
    consent,
    ariaDescribedby,
    headingId,
    ariaDescribedbyId,
    classes,
    descriptionKey,
    descriptionValue,
    stepValue,
    handleBack,
    handleSaveApplication,
    handleContinue,
    disableCondition,
    continueBtnTestid,
    backBtnTestid,
    saveApplicationTestId,
    screenLoading,
    layoutImage,
  } = props;
  const styledClasses = CommonStyles();
  const leadGenerationContext = useContext(LeadGenerationContext);
  const {
    productName,
    currentPage,
    flowInvitation,
    isClicked,
    isApplicationSaved,
    savedDataFlow,
    continueModal,
    userSavedPage,
    complianceData,
    productData,
    flowTypeETB,
    disableContinue,
    termsAndConditionPreSignedURL,
    submitDisabled,
  } = leadGenerationContext;
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints?.down('xs'), {
    defaultMatches: true,
  });
  const productCondition = `${t(productName, { defaultValue: productName })} - ${t('Invitation', {
    defaultValue: 'Invitation',
  })}`;
  const contigoStartPage = productName === PROGRESA_CONTIGO && currentPage === PERSONAL_INFO;
  const [snackbarstate, setSnackbarstate] = useState(false);
  const snackClasses = ReturnToSavedApplicationStyles();
  const [resizePaddingTop, setResizePaddingTop] = useState('');

  const handleClose = () => {
    setSnackbarstate(false);
  };
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const element = document.querySelector('[data-page]');
    if (element) {
      setResizePaddingTop(element.style.paddingTop);
      if (
        element.getAttribute('data-page') === userSavedPage &&
        query.get('returnTopage') === userSavedPage
      ) {
        setSnackbarstate(true);
      }
    }
  }, [complianceData, productData]);
  useEffect(() => {
    const element = document.querySelector('[data-page]');
    if (element) {
      if (snackbarstate) {
        element.style.paddingTop = '105px';
      } else {
        element.style.paddingTop = resizePaddingTop;
      }
    }
  }, [snackbarstate, complianceData, productData]);
  const getPage = () => {
    let returnPage;
    if (userSavedPage === 'address-info' || userSavedPage === 'personal-info') {
      returnPage = true;
    } else {
      returnPage = false;
    }
    return returnPage;
  };

  const handleiFrameLoad = () => {
    if (typeof window !== 'undefined' && window.iFrameResize) {
      window.iFrameResize(
        {
          heightCalculationMethod: 'bodyOffset',
          log: false,
          checkOrigin: ['https://dc-chatbot-qa.orientalbank.com'],
          minHeight: '100',
          minWidth: '140',
          position: 'fixed',
          bottom: '0',
          right: '0',
          autoResize: false,
          sizeWidth: true,
        },
        '#sales_chatbot'
      );
    }
  };

  return (
    <>
      <div className="custom-container-new" role="main" aria-describedby={ariaDescribedby}>
        <div className="secondary-container-new">
          {consent ? (
            <>
              <h1 id="screenSixHeading" className={commonClasses.heading_info}>
                {t('Your Consent', { defaultValue: 'Your Consent' })}
              </h1>
              <Typography
                id="screenSixDesc"
                variant="h2"
                className={commonClasses.sub_heading_info}
              >
                {t(descriptionKey, {
                  defaultValue: descriptionValue,
                  nsSeparator: '|',
                })}
              </Typography>
            </>
          ) : (
            <ProductHeading
              id={headingId}
              ariaDescribedbyId={ariaDescribedbyId}
              productName={commonTernary(
                flowInvitation === 'invitation-form',
                `${productCondition}`,
                t(`${productName}`, { defaultValue: `${productName}` })
              )}
              stepDescription={t(descriptionKey, {
                defaultValue: descriptionValue,
                nsSeparator: '|',
              })}
            />
          )}
          {currentPage !== 'upload-documents-later' ? (
            <Grid container>
              <Grid container item xs={12} sm={8} md={8} spacing={1}>
                <Grid className="stepperLayoutStyles">
                  <StepperWizard steps={stepValue} />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          <Grid container className={styledClasses.marginTopGrid}>
            <Grid item xs={12} sm={8} md={8} style={{ position: 'relative' }}>
              {snackbarstate && (
                <Snackbar
                  id="snackbarId"
                  data-testid="snackbar_testid"
                  open={snackbarstate}
                  className={
                    getPage() ? snackClasses.snackbarPositionMobile : snackClasses.snackbarPosition
                  }
                  classes={{ root: snackClasses.snackbarReturnRoot }}
                  // onClose={handleClose}
                  ContentProps={{
                    className: snackClasses.snackbarRetunStyleViaContentProps,
                    style: { minWidth: 'auto' },
                  }}
                  action={
                    <IconButton
                      id="closeIconId"
                      style={{ position: 'absolute', right: '16px', top: '16px' }}
                      size="small"
                      color={COLOR1}
                      onClick={handleClose}
                      data-testid="snackbarclose_testid"
                    >
                      <img
                        id="closeImgId"
                        src={SnackbarCloseIcon}
                        height="16px"
                        width="16px"
                        alt={t('close')}
                      />
                    </IconButton>
                  }
                  message={
                    <div className={`${snackClasses.message} ${snackClasses.dd}`}>
                      <span style={{ marginRight: '12px' }}>
                        <IconButton
                          classes={{ sizeSmall: snackClasses.snackBarBtn }}
                          style={{ marginTop: 0 }}
                          size="small"
                          color={COLOR1}
                        >
                          <img src={snackbarInfo} alt="Icon" height="24px" width="24px" />
                        </IconButton>
                      </span>
                      <span style={{ marginRight: '6%' }}>
                        {t('Continue your application process from where you left!', {
                          defaultValue: 'Continue your application process from where you left!',
                        })}
                      </span>
                    </div>
                  }
                />
              )}
              {children}
              <Grid
                container
                className={styledClasses.button_grid}
                direction="row"
                alignItems="center"
                style={
                  {
                    // display: 'flex',
                    // flexWrap: 'nowrap',
                    // justifyContent: 'flex-end',
                  }
                }
              >
                <Grid
                  item
                  container
                  className={styledClasses.saveButtonBox}
                  xs={12}
                  // style={{
                  //   display: 'flex',
                  //   flexWrap: 'nowrap',
                  //   // justifyContent: 'flex-end',
                  // }}
                >
                  {!isApplicationSaved &&
                  productMapping(productName) !== 'Progresa Contigo' &&
                  dgiMapping(productName) !== 'DGI Contribution' &&
                  businessMapping(productName) !== 'Prestamo Comercial' &&
                  businessMapping(productName) !== 'Tarjeta de Credito Comercial' &&
                  businessMapping(productName) !== 'Linea de Credito Comercial' &&
                  !contigoStartPage &&
                  currentPage !== 'upload-documents-later' &&
                  currentPage !== 'business-info' &&
                  currentPage !== 'contact-info' &&
                  currentPage !== 'invi-flow-info' &&
                  currentPage !== BUSINESS_COMPLIANCE_INFO &&
                  (flowTypeETB === '' || flowTypeETB === 'NtbFlow') &&
                  // !(
                  //   leadGenerationContext.productName === 'Consumer Credit' &&
                  //   (currentPage === 'document-checklist' && !isClicked)
                  // ) &&
                  !savedDataFlow ? (
                    <Grid
                      item
                      className={`${styledClasses.mobileButtons} ${styledClasses.backBtnGrid}`}
                    >
                      <Button
                        disableFocusRipple
                        disabled={
                          currentPage === 'address-info' || currentPage === 'business-comp'
                            ? disableContinue
                            : false
                        }
                        onClick={handleSaveApplication}
                        data-testid={saveApplicationTestId}
                        className={
                          (currentPage === 'address-info' || currentPage === 'business-comp') &&
                          disableContinue
                            ? styledClasses.saveApplicationButtonDIS
                            : styledClasses.saveApplicationButton
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <img
                            src={
                              (currentPage === 'address-info' || currentPage === 'business-comp') &&
                              disableContinue
                                ? BookmarkIconDisable
                                : BookmarkIcon
                            }
                            alt="blub"
                          />

                          <span style={{ marginLeft: '10px' }}>
                            {t(SAVEYOURAPPLICATION, { defaultValue: SAVEYOURAPPLICATION })}
                          </span>
                        </div>
                      </Button>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {isApplicationSaved &&
                    productMapping(productName) !== 'Progresa Contigo' &&
                    dgiMapping(productName) !== 'DGI Contribution' &&
                    businessMapping(productName) !== 'Prestamo Comercial' &&
                    businessMapping(productName) !== 'Tarjeta de Credito Comercial' &&
                    businessMapping(productName) !== 'Linea de Credito Comercial' &&
                    !contigoStartPage &&
                    currentPage !== 'upload-documents-later' &&
                    currentPage !== 'business-info' &&
                    (currentPage !== 'contact-info' || isCDproduct(productName)) &&
                    currentPage !== 'invi-flow-info' &&
                    currentPage !== BUSINESS_COMPLIANCE_INFO &&
                    // !(
                    //   leadGenerationContext.productName === 'Consumer Credit' &&
                    //   currentPage === 'document-checklist' &&
                    //   !isClicked
                    // ) &&

                    !savedDataFlow && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img width="24px" height="24px" src={SaveContinueIcon} alt="blub" />
                        <Typography
                          className={styledClasses.text_style_saveConti}
                          aria-label={t('Your application process', {
                            defaultValue: 'Your application process',
                          })}
                        >
                          {t('Your application process will auto save', {
                            defaultValue: 'Your application process will auto save',
                          })}
                        </Typography>
                      </div>
                    )}
                  {!isApplicationSaved &&
                    productMapping(productName) !== 'Progresa Contigo' &&
                    dgiMapping(productName) !== 'DGI Contribution' &&
                    businessMapping(productName) !== 'Prestamo Comercial' &&
                    businessMapping(productName) !== 'Tarjeta de Credito Comercial' &&
                    businessMapping(productName) !== 'Linea de Credito Comercial' &&
                    !contigoStartPage &&
                    currentPage !== 'upload-documents-later' &&
                    currentPage !== 'business-info' &&
                    (currentPage !== 'contact-info' || isCDproduct(productName)) &&
                    currentPage !== 'invi-flow-info' &&
                    currentPage !== BUSINESS_COMPLIANCE_INFO &&
                    // !(
                    //   leadGenerationContext.productName === 'Consumer Credit' &&
                    //   currentPage === 'document-checklist' &&
                    //   !isClicked
                    // ) &&
                    savedDataFlow && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img width="24px" height="24px" src={SaveContinueIcon} alt="blub" />
                        <Typography
                          className={styledClasses.text_style_saveConti}
                          aria-label={t('Your application process', {
                            defaultValue: 'Your application process',
                          })}
                        >
                          {t('Your application process will auto save', {
                            defaultValue: 'Your application process will auto save',
                          })}
                        </Typography>
                      </div>
                    )}
                </Grid>
                <Grid
                  item
                  container
                  style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
                  xs={12}
                  className={
                    consent
                      ? clsx(styledClasses.consentButtonGrid)
                      : classes.personal_button_container
                  }
                >
                  {!contigoStartPage &&
                  currentPage !== 'upload-documents-later' &&
                  currentPage !== 'business-info' &&
                  (currentPage !== 'contact-info' || isCDproduct(productName)) &&
                  currentPage !== 'invi-flow-info' ? (
                    // !(
                    //   leadGenerationContext.productName === 'Consumer Credit' &&
                    //   currentPage === 'document-checklist' &&
                    //   !isClicked
                    // )
                    <Grid
                      item
                      className={`${styledClasses.mobileButtons} ${styledClasses.backBtnGrid}`}
                    >
                      <Button
                        disableFocusRipple
                        className={
                          currentPage === 'personal-info-consumer'
                            ? styledClasses.back_button_Consumer
                            : commonTernary(
                                consent,
                                clsx(styledClasses.back_button, styledClasses.consentButtons),
                                styledClasses.back_button
                              )
                        }
                        onClick={handleBack}
                        data-testid={backBtnTestid}
                        style={{ margin: '0px' }}
                      >
                        {t(BACK, { defaultValue: BACK })}
                      </Button>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid
                    item
                    className={`${styledClasses.mobileButtons} ${styledClasses.ContinueBtnGrid}`}
                  >
                    <Button
                      disableFocusRipple
                      disabled={disableCondition}
                      onClick={handleContinue}
                      classes={{
                        disabled:
                          currentPage === 'personal-info-consumer'
                            ? styledClasses.next_disabled_button
                            : commonTernary(
                                (isApplicationSaved || savedDataFlow) && !consent,
                                styledClasses.save_disabled_button,
                                styledClasses.disabled_button
                              ),
                      }}
                      className={
                        currentPage === 'personal-info-consumer'
                          ? styledClasses.next_continue_button
                          : commonTernary(
                              (isApplicationSaved || savedDataFlow) && !consent,
                              styledClasses.save_continue_button,
                              styledClasses.continue_button
                            )
                      }
                      data-testid={continueBtnTestid}
                    >
                      {currentPage === 'personal-info-consumer'
                        ? t('Next: Address info', {
                            defaultValue: 'Next: Address info',
                            nsSeparator: '|',
                          })
                        : currentPage === 'document-checklist' &&
                          !savedDataFlow &&
                          !businessMapping(productName)
                        ? t('Next', {
                            defaultValue: 'Next',
                            nsSeparator: '|',
                          })
                        : t(
                            isCDproduct(productName) &&
                              consent &&
                              termsAndConditionPreSignedURL !== ''
                              ? 'Agree & Submit'
                              : consent || currentPage === 'upload-documents-later'
                              ? 'Submit'
                              : commonTernary(
                                  isApplicationSaved || savedDataFlow,
                                  'Save & Continue',
                                  CONTINUE
                                ),
                            {
                              defaultValue:
                                isCDproduct(productName) &&
                                consent &&
                                termsAndConditionPreSignedURL !== ''
                                  ? 'Agree & Submit'
                                  : consent || currentPage === 'upload-documents-later'
                                  ? 'Submit'
                                  : commonTernary(
                                      isApplicationSaved || savedDataFlow,
                                      'Save & Continue',
                                      CONTINUE
                                    ),
                            }
                          )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!isMobile && (
              <Grid item xs={12} sm={4} md={4}>
                <div>
                  <img
                    src={layoutImage}
                    alt="layout"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
      {!consent && screenLoading && (
        <div className="loader-style">
          <Loader />
        </div>
      )}
      {continueModal && <SimpleTextModal />}
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  ariaDescribedby: PropTypes.string,
  layoutImage: PropTypes.string,
  consent: PropTypes.bool,
  headingId: PropTypes.string,
  ariaDescribedbyId: PropTypes.string,
  descriptionKey: PropTypes.string,
  descriptionValue: PropTypes.string,
  backBtnTestid: PropTypes.string,
  saveApplicationTestId: PropTypes.string,
  continueBtnTestid: PropTypes.string,
  classes: PropTypes.object,
  stepValue: PropTypes.number,
  handleBack: PropTypes.func,
  handleSaveApplication: PropTypes.func,
  handleContinue: PropTypes.func,
  disableCondition: PropTypes.bool,
  screenLoading: PropTypes.bool,
};
