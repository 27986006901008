import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Card, CardContent, Typography, Modal, IconButton, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CloseIconIMG from '../../images/Icon.svg';
import classes from '../SessionTimeout/SessionTimeoutModal.module.css';
import { CONTINUE, INACTIVEMODAL_TEXT_ONE, INACTIVE_MODAL_MSG } from '../../constants';

function DCDLinkExpiryModal({ setExpiryModal, expiryModal, modalMessage }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setExpiryModal(false);
  };

  useEffect(() => {
    if (expiryModal) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [expiryModal]);

  const messgae = modalMessage?.includes('Invalid')
    ? 'The Link is expired or the Review and Sign is completed.'
    : modalMessage || '';

  return (
    <Modal
      className={classes.backdrop}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(233, 233, 235, 0.78)',
        },
      }}
      open={open}
      role="dialog"
      aria-describedby="dialog1Desc"
    >
      <div className={classes.sessionmodal}>
        <Grid container className={classes.sessioncontainer}>
          <IconButton
            src={CloseIconIMG}
            name="closeicon"
            onClick={handleClose}
            tabIndex="0"
            data-testid="expiryModal_id"
            id="expiryModal_id"
            disableRipple
            className={classes.closeOverlay}
          >
            <img
              alt={t(`close`, { defaultValue: 'close' })}
              tabIndex="0"
              src={CloseIconIMG}
              width="20px"
              height="20px"
            />
          </IconButton>
        </Grid>
        <Grid container>
          <Card className={classes.inactivityModalRoot}>
            <CardContent className={classes.card_content}>
              <Typography className={classes.textOneInactiveStyle} id="dialog1Desc">
                {t(messgae, { defaultValue: messgae })}
              </Typography>
              <Button
                disableFocusRipple
                autoFocus
                className={classes.continueAppButton}
                onClick={handleClose}
              >
                {t('Close', { defaultValue: 'Close' })}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </div>
    </Modal>
  );
}

export default withRouter(DCDLinkExpiryModal);

DCDLinkExpiryModal.propTypes = {
  setExpiryModal: PropTypes.func,
  expiryModal: PropTypes.bool,
  modalMessage: PropTypes.string,
};
