import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ThankyouAutomatedStyles } from '../../SaveAndContinue/ThankyouAutomatedStyles';
import Subtraction from '../../../images/Subtraction 1.svg';
import routingNumber from '../../../images/RoutingO.svg';
import dateNumber from '../../../images/DateImO.svg';
import LeadGenerationContext from '../../../context/LeadGenerationContext';
import CustomTooltip from '../../Tooltip/CustomTooltip';
import eye from '../../../images/eye.svg';
import download from '../../../images/download.png';
import Loader from '../../Loader/Loader';

const ThankyouAutomatedCD = () => {
  const { t } = useTranslation();

  const {
    language,
    termsAndConditionPreSignedURL,

    automatedAccDetails,
  } = useContext(LeadGenerationContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints?.down('xs'), {
    defaultMatches: true,
  });
  const isMobile600 = useMediaQuery(theme?.breakpoints?.down('600'), {
    defaultMatches: true,
  });
  const styleClasses = ThankyouAutomatedStyles();
  const openNewAccountTab = () => {
    window.open(
      'https://cibng.ibanking-services.com/eAM/Credential/Index?orgId=878_221571415&FIFID=221571415&brand=878_221571415&appId=CeB&FIORG=878',
      '_blank'
    );
  };

  return (
    <div style={{ position: 'relative', paddingBottom: '100px' }}>
      {!isMobile && (
        <span style={{ position: 'absolute' }}>
          <img className={styleClasses.img} src={Subtraction} alt="image" />
        </span>
      )}
      <Grid container className={styleClasses.welcomeContainer}>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ marginTop: isMobile ? '100px' : '' }}>
            <Typography className={styleClasses.headingText}>
              {t('Welcome to Oriental Prog', {
                defaultValue: 'Welcome to Oriental Bank!',
              })}
            </Typography>
            <Typography className={styleClasses.text}>
              {t('Details about your new account:', {
                defaultValue: 'Details about your new account:',
                nsSeparator: '|',
              })}
            </Typography>
          </div>
        </Grid>
        {!isMobile600 && (
          <>
            <Grid item className={styleClasses.displaytwobox}>
              <div>
                <div
                  style={{
                    height: 'fit-content',
                    padding: '30px 70px 30px 50px',
                    boxSizing: 'border-box',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.21)',
                    borderRadius: '6px',
                    background: '#F0E8F0',
                    marginLeft: '-255px',
                  }}
                >
                  <Typography
                    classes={{
                      body1: styleClasses.typo,
                    }}
                    className={styleClasses.accountDetails}
                  >
                    {t('Account details', {
                      defaultValue: 'Account details',
                    })}
                  </Typography>
                  <div style={{ gap: '30px', display: 'flex' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        paddingTop: '10px',
                        paddingBottom: '18px',
                      }}
                    >
                      <img width="45px" height="45px" src={routingNumber} alt="file-text" />
                      <div style={{ marginLeft: '20px' }}>
                        <Typography
                          className={styleClasses.routingText}
                          classes={{
                            body1: styleClasses.typo,
                          }}
                        >
                          {t('Certificate of Deposit #', {
                            defaultValue: 'Certificate of Deposit #',
                          })}
                        </Typography>
                        <Typography
                          className={styleClasses.routingNumber}
                          classes={{
                            body1: styleClasses.typo,
                          }}
                        >
                          {automatedAccDetails?.accountNumber ||
                            automatedAccDetails?.accountNumber?.[0]}
                          <span style={{ marginLeft: '6px', position: 'absolute' }}>
                            <CustomTooltip
                              placement="bottom-end"
                              text={t(
                                'Please write down your account number. You will need it to subscribe to Online Banking.',
                                {
                                  defaultValue:
                                    'Please write down your account number. You will need it to subscribe to Online Banking.',
                                }
                              )}
                              customAriaLabel={`${t('ACC No Tooltip', {
                                defaultValue: 'ACC No Tooltip',
                              })} ${t('More Info', { defaultValue: 'More Info' })}`}
                            />
                          </span>
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <div
              style={{
                marginTop: '40px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div style={{ marginLeft: language === 'EN' ? '15px' : '-28px' }}>
                {t('View and download your ', {
                  defaultValue: 'View and download your ',
                })}
                <strong>
                  {t('signed CD Terms & Conditions ', {
                    defaultValue: 'signed CD Terms & Conditions ',
                  })}
                </strong>
                {t('document on the link below.', {
                  defaultValue: 'document on the link below.',
                })}
              </div>
              <div
                className="anchor-style"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '15px',
                  marginLeft: language === 'EN' ? '-335px' : '-320px',
                }}
              >
                <a
                  target="_blank"
                  href={termsAndConditionPreSignedURL}
                  data-testid="link_id"
                  rel="noreferrer"
                  className="consent_links"
                >
                  {t('CD Terms & Conditions', {
                    defaultValue: 'CD Terms & Conditions',
                  })}
                  <img
                    src={download}
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                    alt="eye icon"
                    height="24px"
                    width="24px"
                  />
                </a>
              </div>
            </div>
          </>
        )}

        {isMobile600 && (
          <>
            <Grid
              item
              style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                marginBottom: '20px',
              }}
            >
              <div
                style={{
                  height: 'fit-content',
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '30px',
                  boxSizing: 'border-box',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.21)',
                  borderRadius: '6px',
                  background: '#F0E8F0',
                }}
              >
                <Typography
                  classes={{
                    body1: styleClasses.typo,
                  }}
                  style={{ marginLeft: '20px' }}
                >
                  <Typography
                    classes={{
                      body1: styleClasses.typo,
                    }}
                    className={styleClasses.accountDetails}
                  >
                    {t('Account details', {
                      defaultValue: 'Account details',
                    })}
                  </Typography>
                  <div style={{ gap: '5px', display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'flex',
                        marginBottom: '20px',
                        flexDirection: 'row',
                      }}
                    >
                      <img width="40px" height="40px" src={routingNumber} alt="file-text" />
                      <div style={{ marginLeft: '20px' }}>
                        <Typography
                          className={styleClasses.routingText}
                          classes={{
                            body1: styleClasses.typo,
                          }}
                        >
                          {t('Certificate of Deposit #', {
                            defaultValue: 'Certificate of Deposit #',
                          })}
                        </Typography>
                        <Typography
                          className={styleClasses.routingNumber}
                          classes={{
                            body1: styleClasses.typo,
                          }}
                        >
                          {automatedAccDetails.accountNumber}
                          <span style={{ marginLeft: '5px', position: 'absolute' }}>
                            <CustomTooltip
                              placement="bottom-end"
                              text={t(
                                'Please write down your account number. You will need it to subscribe to Online Banking.',
                                {
                                  defaultValue:
                                    'Please write down your account number. You will need it to subscribe to Online Banking.',
                                }
                              )}
                              customAriaLabel={`${t('ACC No Tooltip', {
                                defaultValue: 'ACC No Tooltip',
                              })} ${t('More Info', { defaultValue: 'More Info' })}`}
                            />
                          </span>
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Typography>
              </div>
            </Grid>
            <div
              className="anchor-style"
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <a
                target="_blank"
                href={termsAndConditionPreSignedURL}
                data-testid="link_id"
                rel="noreferrer"
                className="consent_links"
              >
                {t('CD Terms & Conditions', {
                  defaultValue: 'CD Terms & Conditions',
                })}
                <img
                  src={download}
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                  alt="eye icon"
                  height="24px"
                  width="24px"
                />
              </a>
            </div>
          </>
        )}
        <Grid item>
          <div className={styleClasses.container}>
            <div
              className={
                language === 'ES' ? `${styleClasses.containerTES}` : `${styleClasses.containerT}`
              }
              style={{
                textAlign: isMobile600 ? 'center' : '',
              }}
            >
              <Typography
                className={
                  language === 'ES'
                    ? `${styleClasses.spanishText} ${styleClasses.heading}`
                    : styleClasses.heading
                }
                style={{ fontSize: '24px' }}
              >
                {t('View your new account details in Online Banking.', {
                  defaultValue: 'View your new account details in Online Banking.',
                })}
              </Typography>

              <Button
                disableFocusRipple
                data-testid="openAccTab_id"
                className={styleClasses.subscribeButton}
                onClick={openNewAccountTab}
              >
                <span>
                  {t('Go to Online Banking', {
                    defaultValue: 'Go to Online Banking',
                  })}
                </span>
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ThankyouAutomatedCD;
