import { useContext, useEffect } from 'react';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SubmittedThankYouPageStyles } from './SubmittedThankyouScreenStyles';
import LeadGenerationContext from '../../context/LeadGenerationContext';
import orangeCheck from '../../images/orangeCheck.svg';
import people from '../../images/Note_Icon.svg';
import dollar from '../../images/Thankyou_dollar.svg';
import email from '../../images/Notification_Icon.svg';
import {
  RECEIVE_ACC_DETAILS_ON_EMAIL,
  RECEIVE_ACC_DETAILS_ON_EMAIL_PROG,
  REVIEW_APPLICATION,
  THANK_YOU,
  WAITING_PERIOD_45_DAYS_ACCOUNT_OPENING,
  WHAT_COME_NEXT,
  YOU_SUBMITTED_APP,
} from '../../constants';
import { clearContext } from '../clearContext';
import { clearDoChecklistContext } from '../CommercialDeposit/DragDropFiles/DocLabelTypes';
import { getAccessToken } from '../../services/tokenService';
import { productMapping } from '../../services/productMapping';

const SubmittedThankYouPage = (props) => {
  const submitStyles = SubmittedThankYouPageStyles();
  const { t } = useTranslation();
  const { history } = props;
  const leadGenerationContext = useContext(LeadGenerationContext);
  const { setCurrentPage, pauseTimerAndClearInterval, clearPingTimer, productName } =
    leadGenerationContext;

  useEffect(() => {
    setCurrentPage('thank-you-info');
    window.scrollTo(0, 0);
    pauseTimerAndClearInterval();
    clearPingTimer();
  }, []);
  const DEV = 'development';

  const POP_ACTION = 'POP';
  useEffect(() => {
    let unListenSubmission = null;
    unListenSubmission = history.listen(() => {
      if (history.action === POP_ACTION) {
        if (process.env.REACT_APP_ENVIRONMENT === DEV) {
          clearContext(leadGenerationContext);
          clearDoChecklistContext(leadGenerationContext);
          history.push('/');
        } else {
          let url = '';
          if (leadGenerationContext.location === 'PR') {
            url = `https://orientalbank.com/${leadGenerationContext.language?.toLowerCase()}`;
          } else if (leadGenerationContext.location === 'VI') {
            url = `https://orientalbank.com/${leadGenerationContext.language?.toLowerCase()}/${leadGenerationContext.location?.toLowerCase()}`;
          } else {
            url = `https://orientalbank.com/`;
          }
          window.location.href = url;
        }
      }
    });
    return unListenSubmission;
  }, []);
  const handleRoute = async () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      clearContext(leadGenerationContext);
      clearDoChecklistContext(leadGenerationContext);

      const token = await getAccessToken();
      if (token) {
        leadGenerationContext.setAccessToken(token);
        leadGenerationContext.pingTimer(token);
        leadGenerationContext.startTimer();
        history.push('/');
      }
    } else {
      let url = '';
      if (leadGenerationContext.location === 'PR') {
        url = `https://orientalbank.com/${leadGenerationContext.language?.toLowerCase()}`;
      } else if (leadGenerationContext.location === 'VI') {
        url = `https://orientalbank.com/${leadGenerationContext.language?.toLowerCase()}/${leadGenerationContext.location?.toLowerCase()}`;
      } else {
        url = `https://orientalbank.com/`;
      }
      window.location.href = url;
    }
  };

  return (
    <div
      role="main"
      aria-describedby="thankYou submittedApp whatComesNxt reviewApp accDetailOnEmail waitingPeriodToDeposit"
      className={submitStyles.container}
    >
      <div className={submitStyles.main}>
        <div className={submitStyles.div}>
          <img
            width="93px"
            height="93px"
            src={orangeCheck}
            alt={t(THANK_YOU, {
              defaultValue: THANK_YOU,
            })}
          />
          <Typography id="thankYou" className={submitStyles.heading}>
            {t(THANK_YOU, {
              defaultValue: THANK_YOU,
            })}
          </Typography>
        </div>
        <Typography id="submittedApp" className={submitStyles.successAppText}>
          {productMapping(productName) === 'Elite Account'
            ? t('SuccessProg', {
                defaultValue: 'You have successfully submitted your information.',
              })
            : t(YOU_SUBMITTED_APP, {
                defaultValue: YOU_SUBMITTED_APP,
              })}
        </Typography>
        <Typography id="whatComesNxt" className={submitStyles.nextText}>
          {t(WHAT_COME_NEXT, { defaultValue: WHAT_COME_NEXT })}
        </Typography>
        <div>
          <div className={submitStyles.content}>
            <img width="36px" height="36px" src={people} alt="review application" />
            <Typography id="reviewApp" className={submitStyles.text}>
              {t(
                productMapping(productName) === 'Elite Account' ? 'ReviewProg' : REVIEW_APPLICATION,
                {
                  defaultValue: REVIEW_APPLICATION,
                }
              )}
            </Typography>
          </div>
          <div className={submitStyles.content}>
            <img width="36px" height="36px" src={email} alt="Email" />
            <Typography id="accDetailOnEmail" className={submitStyles.text}>
              {t(
                productMapping(productName) === 'Elite Account'
                  ? RECEIVE_ACC_DETAILS_ON_EMAIL_PROG
                  : RECEIVE_ACC_DETAILS_ON_EMAIL,
                {
                  defaultValue:
                    productMapping(productName) === 'Elite Account'
                      ? RECEIVE_ACC_DETAILS_ON_EMAIL_PROG
                      : RECEIVE_ACC_DETAILS_ON_EMAIL,
                }
              )}
            </Typography>
          </div>
          {(leadGenerationContext.productName === 'Libre Account' ||
            leadGenerationContext.productName === 'Cuenta Libre') && (
            <div className={submitStyles.content}>
              <img width="36px" height="36px" src={dollar} alt="waiting period for first deposit" />
              <Typography id="waitingPeriodToDeposit" className={submitStyles.text}>
                {t(WAITING_PERIOD_45_DAYS_ACCOUNT_OPENING, {
                  defaultValue: WAITING_PERIOD_45_DAYS_ACCOUNT_OPENING,
                })}
              </Typography>
            </div>
          )}
        </div>
        <div className={submitStyles.btnAlign}>
          <Button
            disableFocusRipple
            id="screenSubmitHeadingDesc"
            className={submitStyles.thankyouButton}
            autoFocus
            data-testid="visit-orientalbank-testId"
            onClick={handleRoute}
          >
            <span>
              {productMapping(productName) === 'Elite Account'
                ? t('Back to Home page', { defaultValue: 'Back to Home page' })
                : t('Visit orientalbank.com', { defaultValue: 'Visit orientalbank.com' })}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
SubmittedThankYouPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    listen: PropTypes.func,
    action: PropTypes.string,
  }),
};

export default withRouter(SubmittedThankYouPage);
