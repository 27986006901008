import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DoneIcon from '@material-ui/icons/Done';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { stepperStyles } from './StepperStyles';
import StepperConnector from './StepperConnector';
import LeadGenerationContext from '../../context/LeadGenerationContext';
import { businessMapping } from '../../services/businessMapping';
import { productMapping } from '../../services/productMapping';
import {
  YOUR_INFORMATION_BUSINESS,
  PERSONAL_INFORMATION,
  YOUR_ACCOUNT_BUSINESS,
  YOUR_ACCOUNT,
  YOUR_CONSENT,
  BASIC_INFORMATION,
  REVIEW_INFORMATION,
} from '../../constants';
import { isCDproduct } from '../commonCode';

const useStyles = stepperStyles();

export default function StepperWizard(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const leadGenerationContext = useContext(LeadGenerationContext);
  const { productName, language, flowInvitation } = leadGenerationContext;
  const { steps } = props;

  const commonTernary = (condition, result1, result2) => {
    if (condition) {
      return result1;
    }
    return result2;
  };

  return (
    <div className={classes.root}>
      <div
        className={commonTernary(
          language === 'EN',
          classes.stepperWrapperEng,
          classes.stepperWrapperEsp
        )}
      >
        <StepperConnector />
        {isCDproduct(productName) ? (
          <div className={classes.iconWrapper}>
            <div className={`${classes.icon} ${classes.firstIcon}`}>
              <div className="icon_border">
                <div
                  className={`${classes.steps} 
              ${steps == 0 ? classes.current : classes.fullycompleted}`}
                >
                  <span className="check_step">
                    {steps > 0 ? (
                      <DoneIcon className="icon" />
                    ) : (
                      <FiberManualRecordIcon className="icon" style={{ color: '#661C69' }} />
                    )}
                  </span>
                </div>
              </div>

              <span
                style={{ maxWidth: '100px' }}
                className={commonTernary(
                  steps >= 0,
                  commonTernary(
                    businessMapping(productName),
                    classes.businessLabelCompleted,
                    classes.labelCompleted
                  ),
                  commonTernary(businessMapping(productName), classes.businessLabel, classes.label)
                )}
              >
                {businessMapping(productName)
                  ? t(YOUR_INFORMATION_BUSINESS, {
                      defaultValue: YOUR_INFORMATION_BUSINESS,
                    })
                  : t(BASIC_INFORMATION, { defaultValue: BASIC_INFORMATION })}
              </span>
            </div>

            <div
              className={flowInvitation === 'invitation-form' ? classes.inviStyle : classes.icon}
              style={{ left: '35%' }}
            >
              <div className="icon_border">
                <div
                  className={`${classes.steps} 
              ${
                steps < 1 ? classes.upcoming : steps <= 2 ? classes.current : classes.fullycompleted
              }`}
                >
                  <span className="check_step">
                    {steps < 1 ? (
                      <FiberManualRecordIcon className="icon" style={{ color: '#D0D0D0' }} />
                    ) : steps === 1 || steps === 2 ? (
                      <FiberManualRecordIcon className="icon" style={{ color: '#661C69' }} />
                    ) : (
                      <DoneIcon className="icon" />
                    )}
                  </span>
                </div>
              </div>
              <span
                style={businessMapping(productName) ? { maxWidth: '100px' } : { maxWidth: '50px' }}
                className={commonTernary(
                  steps >= 1,
                  commonTernary(
                    businessMapping(productName),
                    classes.businessLabelCompleted,
                    classes.labelCompleted
                  ),
                  commonTernary(businessMapping(productName), classes.businessLabel, classes.label)
                )}
              >
                {businessMapping(productName)
                  ? t(YOUR_ACCOUNT_BUSINESS, { defaultValue: YOUR_ACCOUNT_BUSINESS })
                  : t(PERSONAL_INFORMATION, { defaultValue: PERSONAL_INFORMATION })}
              </span>
            </div>

            <div className={classes.icon} style={{ left: '63%' }}>
              <div className="icon_border">
                <div
                  className={`${classes.steps} ${
                    steps < 3
                      ? classes.upcoming
                      : steps === 3
                      ? classes.current
                      : classes.fullycompleted
                  }`}
                >
                  <span className="check_step">
                    {steps < 3 ? (
                      <FiberManualRecordIcon className="icon" style={{ color: '#D0D0D0' }} />
                    ) : steps === 3 ? (
                      <FiberManualRecordIcon className="icon" style={{ color: '#661C69' }} />
                    ) : (
                      <DoneIcon className="icon" />
                    )}
                  </span>
                </div>
              </div>

              <span
                style={language === 'EN' ? { maxWidth: '73px' } : { maxWidth: '80px' }}
                className={commonTernary(
                  steps >= 3,
                  commonTernary(
                    businessMapping(productName),
                    classes.businessLabelCompleted,
                    classes.labelCompleted
                  ),
                  commonTernary(businessMapping(productName), classes.businessLabel, classes.label)
                )}
              >
                {t(REVIEW_INFORMATION, { defaultValue: REVIEW_INFORMATION })}
              </span>
            </div>

            <div className={classes.icon} style={{ left: '95%' }}>
              <div className="icon_border">
                <div
                  className={`${classes.steps} ${steps <= 3 ? classes.upcoming : classes.current}`}
                >
                  <span className="check_step">
                    {steps <= 3 ? (
                      <FiberManualRecordIcon className="icon" style={{ color: '#D0D0D0' }} />
                    ) : (
                      <FiberManualRecordIcon className="icon" style={{ color: '#661C69' }} />
                    )}
                  </span>
                </div>
              </div>

              <span
                style={language === 'EN' ? { maxWidth: '73px' } : { maxWidth: '80px' }}
                className={commonTernary(
                  steps > 3,
                  commonTernary(
                    businessMapping(productName),
                    classes.businessLabelCompleted,
                    classes.labelCompleted
                  ),
                  commonTernary(businessMapping(productName), classes.businessLabel, classes.label)
                )}
              >
                {t(YOUR_CONSENT, { defaultValue: YOUR_CONSENT })}
              </span>
            </div>
          </div>
        ) : (
          <div className={classes.iconWrapper}>
            <div className={classes.icon}>
              <div
                className={`${classes.steps} 
              ${steps >= 1 ? classes.fullycompleted : ''}`}
              >
                <span>1</span>
              </div>
              <span
                style={{ maxWidth: '100px' }}
                className={commonTernary(
                  steps >= 1,
                  commonTernary(
                    businessMapping(productName),
                    classes.businessLabelCompleted,
                    classes.labelCompleted
                  ),
                  commonTernary(businessMapping(productName), classes.businessLabel, classes.label)
                )}
              >
                {businessMapping(productName)
                  ? t(YOUR_INFORMATION_BUSINESS, {
                      defaultValue: YOUR_INFORMATION_BUSINESS,
                    })
                  : t(PERSONAL_INFORMATION, { defaultValue: PERSONAL_INFORMATION })}
              </span>
            </div>

            <div
              className={flowInvitation === 'invitation-form' ? classes.inviStyle : classes.icon}
              style={{ left: '47%' }}
            >
              <div
                className={`${classes.steps} 
              ${steps >= 2 ? classes.fullycompleted : ''}`}
              >
                <span>2</span>
              </div>
              <span
                style={businessMapping(productName) ? { maxWidth: '100px' } : { maxWidth: '50px' }}
                className={commonTernary(
                  steps >= 2,
                  commonTernary(
                    businessMapping(productName),
                    classes.businessLabelCompleted,
                    classes.labelCompleted
                  ),
                  commonTernary(businessMapping(productName), classes.businessLabel, classes.label)
                )}
              >
                {businessMapping(productName)
                  ? t(YOUR_ACCOUNT_BUSINESS, { defaultValue: YOUR_ACCOUNT_BUSINESS })
                  : t(YOUR_ACCOUNT, { defaultValue: YOUR_ACCOUNT })}
              </span>
            </div>

            <div className={classes.icon} style={{ left: '93%' }}>
              <div className={`${classes.steps} ${steps === 3 ? classes.fullycompleted : ''}`}>
                <span>{flowInvitation === 'invitation-form' ? 2 : 3}</span>
              </div>
              <span
                style={language === 'EN' ? { maxWidth: '73px' } : { maxWidth: '80px' }}
                className={commonTernary(
                  steps >= 3,
                  commonTernary(
                    businessMapping(productName),
                    classes.businessLabelCompleted,
                    classes.labelCompleted
                  ),
                  commonTernary(businessMapping(productName), classes.businessLabel, classes.label)
                )}
              >
                {t(YOUR_CONSENT, { defaultValue: YOUR_CONSENT })}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

StepperWizard.propTypes = {
  steps: PropTypes.number,
};
