import { makeStyles } from '@material-ui/core';
import { RADIO_COLOR } from '../../constants';

export const ThankyouAutomatedStyles = makeStyles(
  (theme) => ({
    headingText: {
      fontSize: '48px',
      fontWeight: 700,
      marginTop: '65px',
      fontFamily: 'TTNormsProRegular',
      lineHeight: 'normal',
      [theme.breakpoints.down('600')]: {
        marginTop: '0px',
        textAlign: 'center',
        padding: '0px 18px 0px 18px',
        fontStyle: 'normal',
      },
      [theme.breakpoints.between('600', '750')]: {
        marginTop: '100px',
      },
    },
    text: {
      fontSize: '24px',
      fontWeight: '400px',
      fontFamily: 'Arial',
      marginTop: '13px',
      marginBottom: '44px',
      [theme.breakpoints.down('600')]: {
        textAlign: 'center',
        padding: '0px 25px 0px 25px',
        marginTop: '30px',
        fontWeight: '400px',
      },
    },
    fileTextIcon: {
      width: '54px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '54px',
      backgroundColor: '#ECD0EC',
      borderRadius: '6px',
    },
    typo: {
      lineHeight: 'unset !important',
    },
    boxTwo: {
      marginLeft: '30px',
      display: 'flex',
      padding: '30px 50px 30px 30px',
      boxSizing: 'border-box',
      border: '1px solid #D0D0D0',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.21)',
      borderRadius: '6px',
      [theme.breakpoints.down('600')]: {
        marginLeft: '0px',
        padding: '30px 131px 30px 30px',
      },
    },
    displaytwobox: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('600')]: {
        display: 'none',
      },
    },
    accountDetails: {
      fontSize: '24px',
      fontWeight: 700,
      fontFamily: 'Arial',
      marginBottom: '26px',
    },
    accountText: {
      fontSize: '16px',
      fontWeight: 400,
      fontFamily: 'Arial',
      marginBottom: '2px',
      // marginTop: '51px',
      // [theme.breakpoints.down('600')]: {
      //   marginTop: '50px',
      // },
    },
    number: {
      fontSize: '32px',
      fontWeight: 700,
      // marginLeft: '20px',
      color: '#000000',
      fontStyle: 'normal',
      lineHeight: 'normal',
      fontFamily: 'Arial',
      [theme.breakpoints.down('600')]: {
        fontSize: '26px',
      },
    },
    divText: {
      display: 'flex',
      width: '191px',
      padding: '8px 12px',
      alignItems: 'flex-start',
      borderRadius: '10px',
      backgroundColor: '#F4EDF4',
      marginTop: '50px',
    },
    container: {
      display: 'flex',
      marginTop: '51px',
      backgroundColor: '#F5F5F5',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('600')]: {
        marginTop: '30px',
      },
      // [theme.breakpoints.between('600', '650')]: {
      //   paddingLeft: '20px',
      // },
    },
    containerT: {
      padding: '41px 0px 51px 0px',
      width: '50%',

      [theme.breakpoints.down('600')]: {
        padding: '30px 0px 43px 0px',

        width: '100%',
      },
      [theme.breakpoints.down('700')]: {
        width: '84%',
      },
      [theme.breakpoints.up('1200')]: {
        width: '50%',
      },
      [theme.breakpoints.up('1312')]: {
        width: '40%',
      },
      [theme.breakpoints.up('1500')]: {
        width: '35%',
      },
      [theme.breakpoints.between('860', '1032')]: {
        width: '60%',
      },
      [theme.breakpoints.between('600', '860')]: {
        width: '70%',
      },
      // [theme.breakpoints.between('600', '650')]: {
      //   paddingLeft: '20px',
      // },
    },
    containerTES: {
      padding: '41px 0px 51px 0px',
      width: '50%',

      [theme.breakpoints.down('600')]: {
        padding: '30px 0px 43px 0px',

        width: '100%',
      },
      [theme.breakpoints.down('700')]: {
        width: '84%',
      },
      [theme.breakpoints.up('1200')]: {
        width: '50%',
      },
      [theme.breakpoints.up('1312')]: {
        width: '40%',
      },
      [theme.breakpoints.up('1500')]: {
        width: '38%',
      },
      [theme.breakpoints.between('860', '1032')]: {
        width: '60%',
      },
      [theme.breakpoints.between('600', '860')]: {
        width: '70%',
      },
      // [theme.breakpoints.between('600', '650')]: {
      //   paddingLeft: '20px',
      // },
    },
    heading: {
      color: '#000000',
      fontWeight: '700',
      fontFamily: 'TTNormsProRegular',
      fontSize: '36px',
      lineHeight: 'normal',
      // fontWeight: 700,
      // [theme.breakpoints.down('600')]: {
      //   fontSize: '32px',
      // },
    },
    secondText: {
      fontFamily: 'Arial',
      fontSize: '16px',
      fontWeight: 400,
      mariginBottom: '25px',
      marginTop: '10px',
      //   textAlign: 'center',
    },
    subscribeButton: {
      borderRadius: '56px',
      backgroundColor: '#661C69',
      width: '264px',
      marginTop: '30px',
      height: '48px',
      //   padding: '14px 50px',
      textTransform: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#FFFFFF',
      textAlign: 'center',
      fontFamily: 'Arial',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '20px',
      '&:hover': {
        backgroundColor: RADIO_COLOR,
      },
      '&:focus': {
        outline: `2px solid ${RADIO_COLOR}`,
        outlineOffset: `2px`,
      },
    },
    infoText: {
      width: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '30px',
      height: '40px',
      backgroundColor: '#ECD0EC',
      borderRadius: '6px',
    },
    routingText: {
      fontSize: '14px',
      fontFamily: 'Arial',
      color: '#444',
      fontWeight: 400,
      marginBottom: '4px',
    },
    routingNumber: {
      gap: '4px',
      fontFamily: 'Arial',
      fontSize: '20px',
      fontWeight: 700,
    },
    img: {
      width: '300px',
      height: '300px',
      [theme.breakpoints.between('1000', '1220')]: {
        width: '200px',
        height: '200px',
      },
      [theme.breakpoints.between('900', '1000')]: {
        width: '150px',
        height: '150px',
      },
      [theme.breakpoints.between('600', '900')]: {
        height: '90px',
        width: '100px',
      },
    },
    mobileImgContainer: {
      position: 'absolute',
      top: '-100px',
      right: '-200px',
      [theme.breakpoints.between('1000', '1220')]: {
        // right: '-120px',
        right: '-152px',
        top: '-50px',
      },
      [theme.breakpoints.between('900', '1000')]: {
        right: '-95px',
        top: '-45px',
      },
      [theme.breakpoints.between('750', '900')]: {
        right: '-70px',
        top: '-45px',
      },
    },
    spanishText: {
      [theme.breakpoints.between('750', '1220')]: {
        width: '100%',
      },
    },
    mobileImg: {
      height: '368px',
      width: '183px',
      [theme.breakpoints.between('1000', '1220')]: {
        width: '170px',
        height: '300px',
      },
      // [theme.breakpoints.between('900', '1000')]: {
      //   height: '250px',
      //   width: '183px',
      // },
      [theme.breakpoints.between('750', '1000')]: {
        height: '300px',
        width: '145px',
      },
    },
    imgMobile: {
      height: '141px',
      width: '100%',
      marginTop: '37px',
      marginBottom: '18px',
    },
    routingContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginRight: '200px',
      [theme.breakpoints.down('600')]: {
        marginRight: '0px',
        width: '100%',
        justifyContent: 'space-evenly',
      },
      [theme.breakpoints.between('600', '650')]: {
        marginRight: '0px',
      },
    },
    routingContainerSpanish: {
      marginRight: '150px !important',
      [theme.breakpoints.between('600', '650')]: {
        marginRight: '90px !important',
      },
      [theme.breakpoints.down('600')]: {
        marginRight: '0px !important',
      },
    },
    welcomeContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    accNumberContainer: {
      display: 'flex',
      [theme.breakpoints.down('600')]: {
        justifyContent: 'center',
      },
    },
    welcomeInnerContainer: {
      [theme.breakpoints.down('600')]: {
        padding: '0 23px',
      },
      [theme.breakpoints.between('600', '650')]: {
        paddingLeft: '20px',
      },
    },
    containerMargin: {
      marginRight: '100px',
      [theme.breakpoints.down('600')]: {
        marginRight: '0px',
      },
    },
    infoContainer: {
      display: 'flex',
      [theme.breakpoints.down('600')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  }),
  { index: 1 }
);
