import { makeStyles } from '@material-ui/core';
import { COLOR1, COLOR4 } from '../../constants';

export const topHeaderStyles = () =>
  makeStyles(
    (theme) => ({
      top_header: {
        display: 'flex',
        background: `${COLOR4} 0% 0% no-repeat padding-box`,
        opacity: 0.85,
        justifyContent: 'space-between',
        height: '66px',
        color: COLOR1,
        alignItems: 'center',
        [theme.breakpoints.down('650')]: {
          // justifyContent: 'space-between',
          // flexWrap: 'inherit',
          height: '65px',
        },
      },

      leftHeaderSection: {
        display: 'flex',
        alignItems: 'center',
      },

      username_block: {
        font: 'normal normal normal 16px/18px Arial',
        letterSpacing: '0.11px',
        opacity: 1,
        marginRight: '52px',
        textTransform: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('650')]: {
          marginRight: '25px',
        },
        [theme.breakpoints.down('420')]: {
          font: 'normal normal normal 13px/16px Arial',
          textAlign: 'center',
        },
        [theme.breakpoints.down('400')]: {
          marginRight: '10px',
        },
      },

      oriental: {
        // display: 'none',
        // [theme.breakpoints.down('650')]: {
        display: 'block',
        width: '96px',
        height: '21px',
        marginLeft: '50px',
        borderRight: '1px solid #C5CEE0',
        paddingRight: '10px',
        marginRight: '5px',
        // },
        [theme.breakpoints.down('650')]: {
          marginLeft: '25px',
        },
      },

      profile_icon: {
        width: '26px',
        height: '26px',
        opacity: 1,
        paddingLeft: '8px',
        [theme.breakpoints.down('370')]: {
          width: '25px',
          height: '25px',
        },
      },

      right_mobile_header: {
        [theme.breakpoints.down('650')]: {
          display: 'flex',
        },
      },

      lang_change_button: {
        font: 'normal normal normal 16px/18px Arial',
        letterSpacing: '0.11px',
        opacity: 1,
        color: '#000000 !important',
        marginRight: '40px',
        '&.Mui-disabled span': {
          opacity: 1,
        },
        '&:focus': {
          border: '2px solid #000',
        },
        [theme.breakpoints.down('650')]: {
          marginRight: '0px',
          paddingY: '0',
        },
        [theme.breakpoints.down('420')]: {
          font: 'normal normal normal 13px/16px Arial',
        },
        [theme.breakpoints.down('370')]: {
          marginRight: '0px',
          font: 'normal normal normal 12px/16px Arial',
        },
      },

      language_icon: {
        width: '26px',
        height: '26px',
        opacity: 1,
        paddingRight: '6px',
        [theme.breakpoints.down('370')]: {
          width: '25px',
          height: '25px',
        },
      },
      orientalText: {
        marginLeft: '50px',
        [theme.breakpoints.down('600')]: {
          marginLeft: '28px',
        },
      },
    }),
    { index: 1 }
  );
