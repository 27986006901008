import { makeStyles } from '@material-ui/core';
import { COLOR2, COLOR4, RADIO_COLOR } from '../../constants';

export const CaptureLoanDetailsStyles = makeStyles(
  (theme) => ({
    main_container: {
      background: '#F8F8F8',
      padding: '20px',
    },
    second_container: {
      background: '#FFFFFF',
    },
    personalText: {
      font: 'normal 700 48px/normal TTNormsProBold',
      color: '#000',
      textTransform: 'uppercase',
    },
    loanText: {
      font: 'normal 700 96px/83px TTNormsProBold',
      color: '#F5821F',
      textTransform: 'uppercase',
    },
    personalLoanContainer: {
      padding: '75px 0px 75px 80px',
    },
    text1: {
      font: 'normal 700 16px/20px TTNormsProBold',
      color: '#000',
      margin: '20px 0px 25px 0px',
    },
    text2: {
      font: 'normal 400 16px/32px TTNormsProRegular',
      color: '#444',
      marginLeft: '5px',
    },
    textContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    fewSteps: {
      font: 'normal 700 31px/36px TTNormsProBold',
      color: '#000',
      padding: '30px',
      background: '#F3F3F3',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '15px',
      width: '184px',
      height: '180px',
      marginTop: '32px',
      position: 'relative',
    },
    heading: {
      font: 'normal 700 32px/normal Arial',
      color: '#000',
    },
    loanDetailsContainer: {
      padding: '35px 90px 30px 37px',
    },
    loanDetails: {
      border: '1px solid #D0D0D0',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.21)',
      boxSizing: 'border-box',
      borderRadius: '6px',
      marginTop: '35px',
      marginBottom: '25px',
      padding: '30px',
    },
    error_message: {
      font: 'normal normal normal 14px/16px Arial',
      letterSpacing: '0.1px',
      color: COLOR2,
      opacity: 1,
      position: 'relative',
      top: '3px',
      display: 'block',
    },
    loanNeededContainer: {
      margin: '20px 0px 0px 0px',
    },
    loanNeededMssg: {
      font: 'normal 400 12px/normal Arial',
      color: '#444',
      marginLeft: '10px',
    },
    mssgContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '15px',
    },
    termOfLoanLabel: {
      font: 'normal 700 14px/18px Arial',
      color: '#4F5052',
      marginTop: '20px',
    },
    unSelectedLoanTerm: {
      borderRadius: '6px',
      border: '1px solid #E8E8E8',
      background: '#FFF',
      font: 'normal 400 20px/20px Arial',
      color: '#000',
      textAlign: 'center',
      minWidth: '46px',
      height: '40px',
      padding: 0,
      '&:hover': {
        background: '#FFF',
        color: '#000',
      },
    },
    selectedLoanTerm: {
      borderRadius: '6px',
      padding: 0,
      background: '#661C69',
      minWidth: '46px',
      height: '40px',
      font: 'normal 700 20px/20px Arial',
      color: '#FFF',
      textAlign: 'center',
      '&:hover': {
        background: '#661C69',
        color: '#FFF',
      },
    },
    loanTermContainer: {
      // display: 'flex',
      // justifyContent: 'space-between',
      display: 'grid',
      gridGap: '12px',
      gridTemplateColumns: 'repeat(auto-fill, 46px)',
      width: '100%',
      margin: '12px 0px 0px 0px',
    },
    radioRoot: {
      width: 0,
      height: '19px',
      paddingTop: 0,
      paddingBottom: 0,
      color: '#661c69',
    },
    radioLabelStyle: {
      // marginLeft: '15px',
      font: 'normal 400 14px/normal Arial',
      color: '#000',
      // display: 'inline',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    radioContainer: {
      marginBottom: '30px',
      display: 'flex',
      alignItems: 'flex-end',
    },
    next_btn: {
      border: `1px solid ${RADIO_COLOR}`,
      background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,
      borderRadius: '56px',
      height: '44px',
      opacity: 1,
      font: 'normal 700 16px/20px Arial',
      textAlign: 'center',
      textTransform: 'none',
      color: COLOR4,
      width: '100%',
      marginTop: '30px',
      padding: '12px 30px',
      '&:hover': {
        backgroundColor: RADIO_COLOR,
      },
      '&.Mui-disabled span': {
        opacity: 0.6,
      },
    },
    next_btn_disabled: {
      '&.MuiButton-root.Mui-disabled': {
        padding: '12px 30px',
        background: `#F3F3F3`,
        border: `#F3F3F3`,
        borderRadius: '56px',
        height: '44px',
        color: '#D0D0D0',
        font: 'normal 700 16px/20px Arial',
        textAlign: 'center',
        letterSpacing: '1.01px',
        textTransform: 'none',
        [theme.breakpoints.down('600')]: {
          font: 'normal 700 16px Arial',
        },
        '&:hover': {
          backgroundColor: '#F3F3F3',
        },
      },
    },
    toolTip: {
      position: 'absolute',
      top: '-2px',
      left: '190px',
    },
  }),
  { index: 1 }
);
