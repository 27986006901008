import { useEffect, useState, useContext } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import LeadGenerationContext from '../../context/LeadGenerationContext';
import { productMapping } from '../../services/productMapping';
import {
  PRODUCT_PERSONAL,
  PRODUCT_PERSONALCD,
  PRODUCT_IRAEDUCATION,
  PRODUCT_IRAFACIL,
  ADDITIONAL_DBA,
  CONTACT_INFO,
  BUSINESS_INFO,
  PERSONAL_INFO,
  ADDRESS_INFO,
  COMPLIANCE_INFO,
  PRODUCT_INFO,
  BUSINESS_COMPLIANCE_INFO,
  BUSINESS_ADDITIONAL_INFO,
  BUSINESS_SURI,
  BUSINESS_ACCOUNT_INFO,
  BUSINESS_COMP,
  FUNDING_INFO,
  CONSENT_INFO,
  DGIADDITIONAL_INFO,
  FINANCE_INFO,
  EDELIVERY_INFO,
  PROGRESA_CONTIGO,
} from '../../constants';
import { dgiMapping } from '../../services/dgiMapping';
import { businessMapping } from '../../services/businessMapping';
import { productTypeMapping } from '../../services/productTypeMapping';
import { isCDproduct } from '../commonCode';

function StepperConnector() {
  const [stepValue, setstepValue] = useState(0);
  const leadGenerationContext = useContext(LeadGenerationContext);

  const { currentPage } = leadGenerationContext;
  const fundingInfo = () => {
    if (dgiMapping(leadGenerationContext.productName)) {
      setstepValue(37);
    } else {
      const prodName = productMapping(leadGenerationContext.productName);
      if (
        prodName === PRODUCT_PERSONAL ||
        prodName === PRODUCT_IRAFACIL ||
        prodName === PRODUCT_IRAEDUCATION ||
        prodName === PRODUCT_PERSONALCD
      ) {
        setstepValue(36);
      } else {
        setstepValue(40);
      }
    }
  };
  const businessComp = () => {
    if (leadGenerationContext.businessContactInformation.businessType === ADDITIONAL_DBA) {
      setstepValue(36);
    } else {
      setstepValue(50);
    }
  };
  const stepValCond = () => {
    if (currentPage === BUSINESS_COMPLIANCE_INFO) {
      setstepValue(18);
    } else if (currentPage === BUSINESS_SURI) {
      setstepValue(33);
    } else if (currentPage === BUSINESS_ADDITIONAL_INFO) {
      setstepValue(33);
    } else if (currentPage === BUSINESS_ACCOUNT_INFO) {
      setstepValue(50);
    } else if (currentPage === 'invi-flow-info') {
      setstepValue(50);
    } else if (currentPage === CONSENT_INFO) {
      setstepValue(100);
    }
  };
  const pageCondition =
    currentPage === CONTACT_INFO ||
    currentPage === BUSINESS_INFO ||
    (currentPage === PERSONAL_INFO && leadGenerationContext.productName === PROGRESA_CONTIGO);
  const pageCondition2 = currentPage === PERSONAL_INFO;
  const certificateOfDeposit = isCDproduct(leadGenerationContext.productName);
  //  && leadGenerationContext.productName === 'Consumer Credit';
  useEffect(() => {
    if (pageCondition) {
      setstepValue(0);
    } else if (currentPage === ADDRESS_INFO) {
      setstepValue(certificateOfDeposit ? 45 : 30);
    } else if (currentPage === COMPLIANCE_INFO) {
      setstepValue(certificateOfDeposit ? 50 : 50);
    } else if (currentPage === PRODUCT_INFO) {
      if (
        businessMapping(leadGenerationContext.productName) &&
        (productTypeMapping(leadGenerationContext.productType) === 'Checking Account' ||
          productTypeMapping(leadGenerationContext.productType) === 'Savings Account')
      ) {
        setstepValue(83);
      } else {
        setstepValue(certificateOfDeposit ? 55 : 63);
      }
    } else if (currentPage === BUSINESS_COMP) {
      businessComp();
      setstepValue(68);
    } else if (currentPage === 'document-checklist') {
      setstepValue(83);
    } else if (currentPage === FUNDING_INFO) {
      fundingInfo();
      setstepValue(certificateOfDeposit ? 65 : 80);
    } else if (currentPage === DGIADDITIONAL_INFO) {
      setstepValue(49);
    } else if (currentPage === FINANCE_INFO) {
      setstepValue(60);
    } else if (currentPage === EDELIVERY_INFO) {
      setstepValue(85);
    }
    stepValCond();
  }, [currentPage]);
  useEffect(() => {
    if (pageCondition) {
      setstepValue(0);
    } else if (currentPage === 'loan-participant-info') {
      setstepValue(17);
    } else if (currentPage === 'consumer-employment-income') {
      setstepValue(38);
    } else if (currentPage === 'loan-review') {
      setstepValue(50);
    } else if (pageCondition2) {
      setstepValue(certificateOfDeposit ? 32 : 22);
    } else if (currentPage === PERSONAL_INFO) {
      setstepValue(17);
    }
  }, [currentPage]);
  return (
    <div className="stepper" style={{ left: '10px' }}>
      <LinearProgress variant="determinate" value={0} />
      <div className="active-progress-bar" style={{ width: `${stepValue}%` }} />
    </div>
  );
}

export default StepperConnector;
