import { Box, Grid, Typography } from '@material-ui/core';
import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Divider } from '@mui/material';
import ReviewLayoutImage from '../../../images/EliteAcCImg3.png';
import { CDProcessingStyles } from './CDProcessingStyles';

import DollarImage from '../../../images/dollar-review.svg';
import PiggySavngsImage from '../../../images/piggy-savings.svg';
import PercentageImage from '../../../images/percentage.svg';
import InterestValueImage from '../../../images/interest-value.svg';
import CDInterestImage from '../../../images/cd-interest.svg';
import { ProductHeadingStyles } from '../ProductHeading/ProductHeadingStyles';
import Layout from '../../Layout';
import { FundingScreenCards } from './FundingScreenCards';

import LeadGenerationContext from '../../../context/LeadGenerationContext';
import { productNameURLMapping } from '../../../services/productNameURLMapping';
import { productMapping } from '../../../services/productMapping';
import SaveAppContactInfo from '../../SaveApp/SaveAppContactInfo';
import { commonTernary, handleETBFlow, isCDproduct, isEtbFlow } from '../../commonCode';

const ReviewCD = (props) => {
  const classes = CDProcessingStyles();
  const Productclasses = ProductHeadingStyles();
  const leadGenerationContext = useContext(LeadGenerationContext);
  const {
    cdFlowOffers,
    interestPaymentMethod,
    location,
    language,
    productName,
    setIsModalSaveLater,
    isModalSaveLater,
  } = leadGenerationContext;
  const { history } = props;
  const { t } = useTranslation();

  const certificateOfDeposit = isCDproduct(productName);
  const handleSaveApplication = (e) => {
    e.preventDefault();
    setIsModalSaveLater(true);
  };

  const handleContinue = () => {
    history?.push(
      `/${location?.toLowerCase()}/${language?.toLowerCase()}${commonTernary(
        isEtbFlow(leadGenerationContext),
        '/express',
        ''
      )}/retail-form/${productNameURLMapping(productName)}/consent-info`
    );
  };

  const handleBack = () => {
    history?.push(
      `/${location?.toLowerCase()}/${language?.toLowerCase()}${commonTernary(
        isEtbFlow(leadGenerationContext),
        '/express',
        ''
      )}/retail-form/${productNameURLMapping(productName)}/funding-info`
    );
  };
  const handleCurrency = (curr) => {
    const currencyValue = curr?.replace(/^0+/, '');
    const newCurrencyValue = currencyValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const newDeposit = `${'$'}${newCurrencyValue}${'.00'}`;
    return newDeposit;
  };
  return (
    <Layout
      handleSaveApplication={handleSaveApplication}
      ariaDescribedby="ReviewCDHeading"
      headingId="ReviewCDHeading"
      ariaDescribedbyId="reviewCDDesc"
      classes={classes}
      descriptionKey={t('Its time to review your application.')}
      descriptionValue={t('Its time to review your application.')}
      stepValue={certificateOfDeposit ? 3 : 1}
      handleBack={handleBack}
      handleContinue={handleContinue}
      // disableCondition={isValidating}
      backBtnTestid="back_button_testid"
      continueBtnTestid="continue_testId"
      // screenLoading={isValidating}
      layoutImage={ReviewLayoutImage}
    >
      <div className={classes.review_grid_margin}>
        <Typography
          id="heading"
          data-testid="heading_testid"
          role="heading"
          variant="h1"
          className={Productclasses.heading_info}
        >
          {t('Review you CD terms!', {
            defaultValue: 'Review you CD terms!',
          })}
        </Typography>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.review_content}>
            <Box display="flex">
              <img src={DollarImage} alt="Dollar Symbol" />
              <Box className={classes.contextBox}>
                <Typography className={classes.cardHeading}>
                  {t('CD Initial Amount', { defaultValue: 'CD Initial Amount' })}:
                </Typography>
                <Typography style={{ fontSize: '32px', fontWeight: '700' }}>
                  {handleCurrency(cdFlowOffers.depositAmount)}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Divider style={{ border: '1px solid #D0D0D0', width: '100%', marginBottom: '18px' }} />
          {/* <Grid container className={classes.reviewCardStyle} spacing={3}>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Box display="flex">
                <img src={PiggySavngsImage} alt="Piggy Savings Image" />
                <Box className={classes.contextBox}>
                  <Typography className={classes.cardHeading}>
                    {t('CD Terms', { defaultValue: 'CD Terms' })}:
                  </Typography>
                  <Typography className={classes.cardValue}>
                    {' '}
                    {cdFlowOffers?.offers?.term}{' '}
                    {cdFlowOffers?.offers?.term === '1'
                      ? t('Month', {
                          defaultValue: 'Month',
                        })
                      : t('Months', {
                          defaultValue: 'Months',
                        })}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Box display="flex">
                <img src={PercentageImage} alt="Percentage Image" />
                <Box className={classes.contextBox}>
                  <Typography className={classes.cardHeading}>
                    {t('Interest Rate', { defaultValue: 'Interest Rate' })}:
                  </Typography>
                  <Typography className={classes.cardValue}>
                    {cdFlowOffers?.offers?.apr}%
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Box display="flex">
                <img src={InterestValueImage} alt="Interest Image" />
                <Box className={classes.contextBox}>
                  <Typography className={classes.cardHeading}>
                    {t('Accrued Interest Method', { defaultValue: 'Accrued Interest Method' })}:
                  </Typography>
                  <Typography className={classes.cardValue}>
                    {t(interestPaymentMethod?.accuredIntMethod, {
                      defaultValue: interestPaymentMethod?.accuredIntMethod,
                    })}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={5} md={5} sm={12}>
              <Box display="flex">
                <img src={CDInterestImage} alt="Interest Image" />
                <Box className={classes.contextBox}>
                  <Typography className={classes.cardHeading}>
                    {t('CD Interest (APY)', { defaultValue: 'CD Interest (APY)' })}:{' '}
                  </Typography>
                  <Typography className={classes.cardValue}>
                    {' '}
                    {cdFlowOffers?.offers?.apy}%
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid> */}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                  marginBottom: '24px',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Box display="flex">
                    <img src={PiggySavngsImage} alt="Piggy Savings Image" />
                    <Box className={classes.contextBox}>
                      <Typography className={classes.cardHeading}>
                        {t('CD Terms', { defaultValue: 'CD Terms' })}:
                      </Typography>
                      <Typography className={classes.cardValue}>
                        {' '}
                        {cdFlowOffers?.offers?.term}{' '}
                        {cdFlowOffers?.offers?.term === '1'
                          ? t('Month', {
                              defaultValue: 'Month',
                            })
                          : t('Months', {
                              defaultValue: 'Months',
                            })}
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Box display="flex">
                    <img src={InterestValueImage} alt="Interest Image" />
                    <Box className={classes.contextBox}>
                      <Typography className={classes.cardHeading}>
                        {t('Accrued Interest Method', { defaultValue: 'Accrued Interest Method' })}:
                      </Typography>
                      <Typography className={classes.cardValue}>
                        {t(interestPaymentMethod?.accuredIntMethod, {
                          defaultValue: interestPaymentMethod?.accuredIntMethod,
                        })}
                      </Typography>
                    </Box>
                  </Box>
                </div>
              </Box>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{
                  display: { xs: 'none', sm: 'block' },
                  margin: '0 16px',
                  border: '1px solid #D0D0D0',
                }}
              />
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Box display="flex">
                    <img src={PercentageImage} alt="Percentage Image" />
                    <Box className={classes.contextBox}>
                      <Typography className={classes.cardHeading}>
                        {t('Interest Rate', { defaultValue: 'Interest Rate' })}:
                      </Typography>
                      <Typography className={classes.cardValue}>
                        {cdFlowOffers?.offers?.apr}%
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Box display="flex">
                    <img src={CDInterestImage} alt="Interest Image" />
                    <Box className={classes.contextBox}>
                      <Typography className={classes.cardHeading}>
                        {t('CD Interest (APY)', { defaultValue: 'CD Interest (APY)' })}:{' '}
                      </Typography>
                      <Typography className={classes.cardValue}>
                        {' '}
                        {cdFlowOffers?.offers?.apy}%
                      </Typography>
                    </Box>
                  </Box>
                </div>
              </Box>
            </Box>
          </Box>
        </Grid>
      </div>
      {isModalSaveLater && (
        <SaveAppContactInfo openModal={isModalSaveLater} currentPage="cd-review" />
      )}
    </Layout>
  );
};

export default withRouter(ReviewCD);

ReviewCD.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};
