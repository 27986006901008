/* eslint-disable max-lines */
import { useContext, useRef } from 'react';
import parser from 'html-react-parser';
import { PropTypes } from 'prop-types';
import { Message, Bubble, MessageText } from '@livechat/ui-kit';
import { ChatWrapperCss } from '../../utils/WrapperCss';
import liveAgentIcon from '../images/customer_service_icon.png';
import accountIntentImg from '../images/accountIntent3.22.png';
import AccountInfo from './AccountInfo';
import ChatBubbleIcon from './ChatBubbleIcon';
import Spinner from '../spinner/Spinner';
import StepOne from '../wrapup/StepOne';
import ChangeLanguage from '../language/ChangeLanguage';
// import StartCalendar from '../calendar/StartCalendar';
// import EndCalendar from '../calendar/EndCalendar';
import WaitingTimeFlow from '../liveAgent/WaitingTimeFlow';
import AgentBusyFlow from '../liveAgent/AgentBusyFlow';
import ReconnectFlow from '../liveAgent/ReconnectFlow';
import ShowWaitTimeAgain from '../liveAgent/ShowWaitTimeAgain';
import QuickReplies from './QuickReplies';
import {
  handleServiceCaseButtons,
  handleGreetButtons,
  languageChangeApiCall,
  PostLextApi,
  setChatBotIntents,
} from '../../utils/CommonFunctions';
import MessageStatus from './MessageStatus';
import {
  ACCOUNT_NO,
  ADD_DTLS_NO,
  ALL_AGENTS_BUSY_EST_TIME_ENG,
  ALL_AGENTS_BUSY_EST_TIME_ESP,
  CANCEL_CHAT,
  CARD_NO,
  CB_LANGUAGE_SWITCH_ENGLISH,
  CB_LANGUAGE_SWITCH_SPANISH,
  CHAT_INTENT,
  CONNECTING_LIVE_AGENT,
  CREDIT,
  ENG,
  ENTER_CARD_NUMBER_OPTIONAL_ENG,
  ENTER_CARD_NUM_ENG,
  ENTER_CARD_NUM_ESP,
  ENTER_CARD_NUM_OPTIONAL_ESP,
  ESP,
  ESTIMATED_WAIT_TIME_IS,
  ESTIMATED_WAIT_TIME_IS_ESP,
  ESTO_ES_OPCIONAL,
  ESTO_ES_OPTIONAL,
  FINALLY_YOUR_ACC_NUM_ENG,
  FINALLY_YOUR_ACC_NUM_ESP,
  GREET_INTENT,
  HAS_ENDED,
  HA_TERMINADO,
  IGNORE_MESSAGE,
  IN_PROGRESS,
  KNOW_TO_CHAT_NO,
  LETS_CONTINUE_ENG,
  LETS_CONTINUE_ESP,
  LIVE_AGENT_GENERAL_INFO,
  LIVE_AGENT_ICON,
  LIVE_AGENT_SERVICE_ACCOUNT,
  NO_COUNTRY_TRAVEL,
  PROVIDE_ACC_NUM_ENG,
  PROVIDE_ACC_NUM_ESP,
  PUERTO_RICO_WEBFORM,
  REFER_TO_THIS_IMAGE,
  REFER_TO_THIS_IMAGE_ESP,
  REQUEST_CALL_BACK_ENG,
  REQUEST_CALL_BACK_ESP,
  SELECCIONA_START_END_DATE_ESP,
  SELECCIONA_START_RETURN_DATE_ESP,
  SELECT_START_END_DATE_ENG,
  SELECT_START_END_DATE_ENG_CAPS,
  SELECT_START_END_DATE_ESP,
  SELECT_START_RETURN_DATE_ENG,
  SELECT_START_RETURN_DATE_ENG_CAPS,
  SHOW_FEEDBACK,
  SKIP_TEXT_ENG,
  SKIP_TEXT_ESP,
  SORRY_AGENTS_ARE_BUSY_ENG,
  SORRY_AGENTS_ARE_BUSY_ESP,
  SWITCH_LANGUAGE,
  TRAVEL_DATES,
  USVI_WEBFORM,
  WANT_CONTACT_BACK_ENG,
  WANT_CONTACT_BACK_ESP,
  YOUR_ACC_NUM_ENG,
  YOUR_ACC_NUM_ESP,
  ZERO_SEVEN_ONE,
  ZERO_SEVEN_TWO,
  SELECT_DIRA_MSG_ENG,
  SELECT_DIRA_MSG_ESP,
  SELECT_EIRA_MSG_ENG,
  SELECT_EIRA_MSG_ESP,
  ATTACHMENT_LIST,
  TRAVEL_RISTRICT_DESTINATION_MESG_EN,
  TRAVEL_RISTRICT_DESTINATION_MESG_ES,
  DEVICE_IPHONE,
  DEVICE_ANDROID,
  EDUCATIVE_TRADITIONAL_IRA_ESP,
  EDUCATIVE_TRADITIONAL_IRA_ENG,
  LIVE_AGENT_SERVICE_ACCOUNT_NEW_CONCIERGE,
  LIVE_AGENT_SERVICE_ACCOUNT_EXISTING_CONCIERGE,
} from '../../Constants';
import { CommonStyles } from '../../styles/ChatBotCommonStyles';
import { getParameters } from '../../CommonFunction';
import LeadGenerationContext from '../../../../context/LeadGenerationContext';
// import FileUpload from '../FileUpload/FileUpload';

const useCommonStyles = CommonStyles();

const useChatWrapperCss = ChatWrapperCss();
const ChatMessageContainer = (props) => {
  const CommoncssStyles = useCommonStyles();

  const currentReplies = useRef([]);
  const WrapperCss = useChatWrapperCss();
  const {
    messages,
    countriesList,
    lexError,
    englishBot,
    startDateTravel,
    endDateTravel,
    agentTypingFlag,
    intentLang,
    slotToElicit,
    runningAWSConnect,
    sessionID,
    sendToLex,
    setMaxLength,
    handleMessage,
    onEndDateSelectHandler,
    handeLanguageChangeMessage,
    handlerLiveAgentTrigger,
    ReconnectToAwsConnect,
    handleWrapUpStepOneYes,
    setDefaultTextContent,
    handleWrapUpStepOneNo,
    onStartDateSelectHandler,
    handeButtonLinkMessage,
    handleWrapupMessage,
    handleLanguageChange,
    setIntentLang,
    setWebFormType,
    handlePopupRunningCase,
    setShowModal,
    closeEvent,
    setIsCancelProcess,
    handlerPageIntent,
    askingForWaitingTime,
    setServiceFormTrigger,
    setGeneralFormTrigger,
    formInProcessTrigger,
    spinnerHandler,
    setAutoCompleteFlag,
    setShowAutoComplete,
    setShowRegisterCustomerButton,
    dropDownValues,
    uploadDocuments,
    attachmentLoader,
    cancelUploadDocuments,
    handleEncryptedDataNeeded,
    setNewConciergeCustomer,
    setExistingConciergeCustomer,
    lexSuccessHandler,
    lexFailedHandler,
    handlePostLexToken,
    handlePostLexCatch,
    handleEnglishBot,
    state,
  } = props;
  const {
    chatDeviceType,
    chatDeviceOS,
    SspUserLogin,
    uuid,
    isEncryptionNeeded,
    customerGuid,
    dobAtLogin,
  } = state;
  const leadGenerationContext = useContext(LeadGenerationContext);
  const { currentPage } = leadGenerationContext;
  const showQuickReplies = (responseCard) => {
    const options = [];
    if (responseCard) {
      currentReplies.current = [...currentReplies.current, ...responseCard];
      if (responseCard.isServiceCase) {
        responseCard.map((button) =>
          options.push({
            text: button.title,
            value: button.subtitle,
            className: 'serviceCaseButton',
          })
        );
      } else {
        responseCard.map((button) => options.push({ text: button.title, value: button.subtitle }));
      }
      return <QuickReplies options={options} onSelect={quickReplySelected} />;
    }
    return null;
  };
  const quickReplySelected = (optionText, optionValue) => {
    handleServiceCaseButtons();
    if (optionValue === KNOW_TO_CHAT_NO || optionValue === GREET_INTENT) {
      handleGreetButtons();
    }

    if (
      optionValue.toLowerCase().startsWith('http') ||
      optionValue.toLowerCase().startsWith('tel:') ||
      optionValue.toLowerCase().startsWith('mailto:')
    ) {
      handeButtonLinkMessage({ text: optionText, value: '', isButtonMessage: true }, true);
      window.open(optionValue);
    } else if (optionValue === DEVICE_IPHONE || optionValue === DEVICE_ANDROID) {
      handeButtonLinkMessage(
        { text: optionValue, value: '', isButtonMessage: false, qrCode: true },
        false
      );
      setTimeout(() => {
        const messageContainer = document.getElementById('messageContainer');
        if (messageContainer) {
          messageContainer.scrollTop =
            messageContainer.scrollHeight - messageContainer.clientHeight;
        }
      }, 500);
    } else if (optionValue === ATTACHMENT_LIST) {
      handleWrapupMessage({ text: optionValue, value: '', isButtonMessage: true }, false, {
        showAttachmentComponent: true,
      });
    } else if (optionValue === ZERO_SEVEN_ONE) {
      handleLanguageChangeYes();
    } else if (optionValue === SWITCH_LANGUAGE) {
      if (intentLang === CB_LANGUAGE_SWITCH_ENGLISH) {
        handleMessage({ text: optionText, value: CB_LANGUAGE_SWITCH_SPANISH }, true);
        setIntentLang(CB_LANGUAGE_SWITCH_SPANISH);
        setTimeout(() => {
          handleLanguageChangeYes();
        }, 1000);
      } else {
        handleMessage({ text: optionText, value: CB_LANGUAGE_SWITCH_ENGLISH }, true);
        setIntentLang(CB_LANGUAGE_SWITCH_ENGLISH);
        setTimeout(() => {
          handleLanguageChangeYes();
        }, 1000);
      }
    } else if (optionValue === CANCEL_CHAT) {
      handleWrapUpStepOneYes();
    } else if (optionValue === PUERTO_RICO_WEBFORM) {
      handeButtonLinkMessage({ text: optionText, value: '', isButtonMessage: true }, true);
      setWebFormType(PUERTO_RICO_WEBFORM);
      handlePopupRunningCase(true);
      setShowModal(true);
    } else if (optionValue === USVI_WEBFORM) {
      handeButtonLinkMessage({ text: optionText, value: '', isButtonMessage: true }, true);
      setWebFormType(USVI_WEBFORM);
      handlePopupRunningCase(true);
      setShowModal(true);
    } else if (optionValue === ZERO_SEVEN_TWO) {
      handleLanguageChangeNo();
    } else if (optionValue === SHOW_FEEDBACK) {
      handeButtonLinkMessage({ text: optionText, value: '', isButtonMessage: true }, true);
      closeEvent();
    } else if (optionValue === ADD_DTLS_NO) {
      setIsCancelProcess(false);
      handlerPageIntent();
      handleMessage({ text: optionText, value: optionValue }, true);
    } else if (optionValue === LIVE_AGENT_SERVICE_ACCOUNT) {
      setServiceFormTrigger(true);
      formInProcessTrigger(true);
      spinnerHandler(true);
      setShowRegisterCustomerButton(false);
    } else if (optionValue === LIVE_AGENT_GENERAL_INFO) {
      setGeneralFormTrigger(true);
      formInProcessTrigger(true);
      spinnerHandler(true);
      setShowRegisterCustomerButton(true);
    } else if (optionValue === LIVE_AGENT_SERVICE_ACCOUNT_EXISTING_CONCIERGE) {
      formInProcessTrigger(true);
      spinnerHandler(true);
      setShowRegisterCustomerButton(true);
      setExistingConciergeCustomer(true);
    } else if (optionValue === LIVE_AGENT_SERVICE_ACCOUNT_NEW_CONCIERGE) {
      formInProcessTrigger(true);
      spinnerHandler(true);
      setShowRegisterCustomerButton(true);
      setNewConciergeCustomer(true);
    } else if (optionValue === NO_COUNTRY_TRAVEL) {
      setTimeout(() => {
        handleMessage({ text: optionText, value: optionValue }, true);
        setShowAutoComplete(false);
      }, 1000);
    } else if (optionValue === CHAT_INTENT) {
      handlerPageIntent();
      setTimeout(() => {
        handleMessage({ text: optionText, value: optionValue }, true);
      }, 1000);
    } else if (optionValue === CREDIT) {
      setAutoCompleteFlag(false);
      handleMessage({ text: optionText, value: optionValue }, true);
    } else {
      handleMessage({ text: optionText, value: optionValue }, true);
    }
  };
  const handleAgentBusyFlowYes = () => {
    const yesButton = englishBot ? 'Yes' : 'Sí';
    messages.push({ message: yesButton, isButtonMessage: true, isOwn: true });
  };
  const handleAgentBusyFlowNo = () => {
    messages.push({ message: 'No', isButtonMessage: true, isOwn: true });
  };
  const handleAccountNumber = () => {
    document.getElementById('messageText').focus();
  };
  const RequestCallbackScript = englishBot ? REQUEST_CALL_BACK_ENG : REQUEST_CALL_BACK_ESP;
  const pegaConnectTrigger = () => {
    setTimeout(() => {
      const defaultContent = englishBot ? REQUEST_CALL_BACK_ENG : REQUEST_CALL_BACK_ESP;
      handleMessage({ text: defaultContent, value: 'pega_connect' }, true);
    }, 3000);
  };
  const buttonMessageWaitingForAgentTrigger = (text, status) => {
    if (runningAWSConnect) {
      if (status) {
        askingForWaitingTime();
        handeButtonLinkMessage({ text, value: '', isButtonMessage: false }, status);
      }
    } else {
      handleMessage({ text, value: text }, true);
    }
  };
  const buttonMessageOnbackToLexTrigger = (text, status) => {
    setTimeout(() => {
      handeButtonLinkMessage({ text, value: '', isButtonMessage: false }, false);
    }, 3000);
  };
  const handleSkipThisStep = () => {
    if (
      messages[messages.length - 1].message ===
        (englishBot ? FINALLY_YOUR_ACC_NUM_ENG : FINALLY_YOUR_ACC_NUM_ESP) ||
      messages[messages.length - 1].message ===
        (englishBot ? YOUR_ACC_NUM_ENG : YOUR_ACC_NUM_ESP) ||
      slotToElicit === ACCOUNT_NO ||
      slotToElicit === CARD_NO ||
      messages[messages.length - 1].message ===
        (englishBot ? ENTER_CARD_NUMBER_OPTIONAL_ENG : ENTER_CARD_NUM_OPTIONAL_ESP)
    ) {
      const skipText = englishBot ? SKIP_TEXT_ENG : SKIP_TEXT_ESP;
      handeButtonLinkMessage({ text: skipText, value: '', isButtonMessage: false }, true);
      sendToLex('skip');
      setMaxLength(250);
    }
  };
  const handleLanguageChangeNo = () => {
    messages.push({ message: 'No', isButtonMessage: true, isOwn: true });
    const defaultContent =
      intentLang === CB_LANGUAGE_SWITCH_SPANISH ? LETS_CONTINUE_ESP : LETS_CONTINUE_ENG;

    handeLanguageChangeMessage(
      { text: defaultContent, value: defaultContent, languageChangeYesNo: false },
      false
    );
    if (intentLang === CB_LANGUAGE_SWITCH_ENGLISH) {
      handleEnglishBot(true);
      handleLanguageChange(ENG, 'click');
    } else {
      handleEnglishBot(false);
      handleLanguageChange(ESP, 'click');
    }
  };
  const handleLanguageChangeYes = async () => {
    localStorage.removeItem('textContent');
    await languageChangeApiCall(sessionID, englishBot, 'intent');

    if (intentLang === CB_LANGUAGE_SWITCH_SPANISH) {
      handleLanguageChange(ENG, 'intent');
    } else {
      handleLanguageChange(ESP, 'intent');
    }
  };
  const handleLanguageChangeYesButtonClick = async () => {
    setDefaultTextContent('');
    await languageChangeApiCall(sessionID, englishBot, 'Click');

    const intentMessage = setChatBotIntents(currentPage);
    if (intentLang === CB_LANGUAGE_SWITCH_ENGLISH) {
      handleMessage({ text: 'Sí', value: intentMessage }, true);
      setIntentLang(intentMessage);
      handleEnglishBot(false);
      handleLanguageChange(ESP, 'click');
    } else {
      handleMessage({ text: 'Yes', value: intentMessage }, true);
      setIntentLang(intentMessage);
      setIntentLang(CB_LANGUAGE_SWITCH_ENGLISH);
      handleEnglishBot(true);
      handleLanguageChange(ENG, 'click');
    }

    // const textMessage = setChatBotIntents(currentPage);
    // const params = getParameters(
    //   textMessage,
    //   slotToElicit,
    //   englishBot,
    //   sessionID,
    //   chatDeviceType,
    //   chatDeviceOS,
    //   SspUserLogin,
    //   uuid,
    //   isEncryptionNeeded,
    //   customerGuid,
    //   dobAtLogin
    // );
    // console.log({ intent: textMessage });
    // if (textMessage?.includes('DSC_SCREEN')) {
    //   await PostLextApi(
    //     params,
    //     lexSuccessHandler,
    //     lexFailedHandler,
    //     handlePostLexToken,
    //     handlePostLexCatch
    //   );
    // }
  };
  return (
    <>
      {messages.map((message) => {
        let bubbleContainerClass = '';
        let bubbleClass = '';
        let bubbleClass2 = '';
        let messagetextFontStyle = '';
        if (message.isOwn) {
          bubbleClass = WrapperCss.bubbleBotStyle;
          bubbleClass2 = WrapperCss.bubbleBotStyle;
          if (message.showEndDateCalendar) {
            bubbleContainerClass = WrapperCss.bubbleOwnContainerStyle2;
          } else {
            bubbleContainerClass = WrapperCss.bubbleOwnContainerStyle;
          }
        } else {
          bubbleContainerClass = WrapperCss.bubbleBotContainerStyle;
          bubbleClass2 = WrapperCss.agentFileStyle;
          if (message.message.includes(CONNECTING_LIVE_AGENT)) {
            bubbleClass = WrapperCss.agentWaitStyle;
          } else if (
            message.message.includes(HAS_ENDED) ||
            message.message.includes(HA_TERMINADO)
          ) {
            bubbleClass = WrapperCss.agentchatStyle;
            messagetextFontStyle = WrapperCss.agentchatFontStyle;
          } else if (message.agentEnterMessage) {
            bubbleClass = WrapperCss.agentEnteredTextStyle;
            messagetextFontStyle = WrapperCss.agentEnteredStyle;
          } else {
            bubbleClass = WrapperCss.bubbleOwnStyle;
            messagetextFontStyle = WrapperCss.noAgentchatFontStyle;
          }
        }
        if (message.message !== '') {
          if (
            message.message !== SHOW_FEEDBACK &&
            message.message !== IGNORE_MESSAGE &&
            message.message !== IN_PROGRESS &&
            !message.message.includes('Customer Details:\n') &&
            message.message !== REQUEST_CALL_BACK_ENG &&
            message.message !== REQUEST_CALL_BACK_ESP &&
            !message.isButtonMessage
          ) {
            return (
              <>
                <Message isOwn={message.isOwn} className="message-container">
                  {!message.qrCode && <ChatBubbleIcon message={message} />}
                  <div
                    className={`bubble-container ${bubbleContainerClass}`}
                    style={message.qrCode ? { width: '100%', textAlign: 'center' } : {}}
                  >
                    {message.attachmentFile ? (
                      <Bubble isOwn={message.isOwn} className={bubbleClass2}>
                        <MessageText>
                          <a href={message.messageLink} alt="file">
                            {parser(message.message)}
                          </a>
                        </MessageText>
                      </Bubble>
                    ) : message.showAttachmentComponent ? (
                      <Bubble isOwn={message.isOwn} className={bubbleClass2} id="fileUploadWrapper">
                        {/* <FileUpload
                          englishBot={englishBot}
                          uploadDocuments={uploadDocuments}
                          cancelUploadDocuments={cancelUploadDocuments}
                        /> */}
                      </Bubble>
                    ) : (
                      <>
                        {message.qrCode ? (
                          <img
                            alt="qr code"
                            // src={getQRCode(message.message)}
                            style={{ marginLeft: '11%' }}
                          />
                        ) : (
                          <Bubble isOwn={message.isOwn} className={bubbleClass}>
                            {message.agentEnterMessage && (
                              <img
                                src={liveAgentIcon}
                                alt={LIVE_AGENT_ICON}
                                className={CommoncssStyles.chat_msg_design_img}
                              />
                            )}
                            <MessageText className={messagetextFontStyle}>
                              {parser(message.message)}
                            </MessageText>
                          </Bubble>
                        )}
                        <MessageStatus message={message} lexError={lexError} />
                      </>
                    )}

                    {(message.message.includes(REFER_TO_THIS_IMAGE) ||
                      message.message.includes(REFER_TO_THIS_IMAGE_ESP)) && (
                      <div className={CommoncssStyles.chat_msg_align}>
                        <img
                          width="100%"
                          height="100%"
                          src={accountIntentImg}
                          alt="account intent"
                        />
                      </div>
                    )}
                    {message.responseCard && showQuickReplies(message.responseCard)}
                    {(message.message === FINALLY_YOUR_ACC_NUM_ENG ||
                      message.message === FINALLY_YOUR_ACC_NUM_ESP ||
                      message.message === YOUR_ACC_NUM_ENG ||
                      message.message === YOUR_ACC_NUM_ESP ||
                      message.message.includes(ENTER_CARD_NUM_ENG) ||
                      message.message.includes(ENTER_CARD_NUM_ESP) ||
                      message.message.includes(PROVIDE_ACC_NUM_ENG) ||
                      message.message.includes(PROVIDE_ACC_NUM_ESP) ||
                      message.message.includes(ESTO_ES_OPTIONAL) ||
                      message.message.includes(ESTO_ES_OPCIONAL)) && (
                      <AccountInfo
                        handleSkipThisStep={handleSkipThisStep}
                        handleAccountNumber={handleAccountNumber}
                        englishBot={englishBot}
                        message={message.message}
                      />
                    )}

                    {/* Live Agent components start */}
                    {(message.message.includes(ALL_AGENTS_BUSY_EST_TIME_ENG) ||
                      message.message.includes(ALL_AGENTS_BUSY_EST_TIME_ESP)) && (
                      <WaitingTimeFlow
                        message={message.message}
                        pegaConnectTrigger={pegaConnectTrigger}
                        englishBot={englishBot}
                        buttonMessageWaitingForAgentTrigger={buttonMessageWaitingForAgentTrigger}
                        buttonMessageOnbackToLexTrigger={buttonMessageOnbackToLexTrigger}
                        liveAgentTrigger={handlerLiveAgentTrigger}
                      />
                    )}
                    {(message.message.includes(ESTIMATED_WAIT_TIME_IS) ||
                      message.message.includes(ESTIMATED_WAIT_TIME_IS_ESP)) && (
                      <ShowWaitTimeAgain
                        pegaConnectTrigger={pegaConnectTrigger}
                        englishBot={englishBot}
                        buttonMessageOnbackToLexTrigger={buttonMessageOnbackToLexTrigger}
                        liveAgentTrigger={handlerLiveAgentTrigger}
                      />
                    )}
                    {(message.message.includes(SORRY_AGENTS_ARE_BUSY_ENG) ||
                      message.message.includes(SORRY_AGENTS_ARE_BUSY_ESP)) && (
                      <AgentBusyFlow
                        englishBot={englishBot}
                        pegaConnectTrigger={pegaConnectTrigger}
                        buttonMessageOnbackToLexTrigger={buttonMessageOnbackToLexTrigger}
                        handleAgentBusyFlowYes={handleAgentBusyFlowYes}
                        handleAgentBusyFlowNo={handleAgentBusyFlowNo}
                        liveAgentTrigger={handlerLiveAgentTrigger}
                        handleMessage={handleMessage}
                      />
                    )}
                    {(message.message.includes(WANT_CONTACT_BACK_ENG) ||
                      message.message.includes(WANT_CONTACT_BACK_ESP)) && (
                      <ReconnectFlow
                        pegaConnectTrigger={pegaConnectTrigger}
                        ReconnectToAwsConnect={ReconnectToAwsConnect}
                        englishBot={englishBot}
                        buttonMessageOnbackToLexTrigger={buttonMessageOnbackToLexTrigger}
                      />
                    )}
                    {/* Live Agent components end */}

                    {message.languageChangeYesNo && (
                      <ChangeLanguage
                        englishBot={englishBot}
                        langValue={message.chatLanguage}
                        handleLanguageChangeYesButtonClick={handleLanguageChangeYesButtonClick}
                        handleLanguageChangeNo={handleLanguageChangeNo}
                      />
                    )}
                    {message.showYesNo && (
                      <StepOne
                        handleWrapUpStepOneYes={handleWrapUpStepOneYes}
                        englishBot={englishBot}
                        message={message.message}
                        handleWrapUpStepOneNo={handleWrapUpStepOneNo}
                        handleMessage={handleMessage}
                      />
                    )}
                  </div>
                </Message>{' '}
              </>
            );
          }
        }

        if (
          !message.isButtonMessage &&
          !agentTypingFlag &&
          message.message !== IN_PROGRESS &&
          message.message !== RequestCallbackScript
        ) {
          return <Spinner key="spinner" />;
        }

        return null;
      })}
      {attachmentLoader && <Spinner key="spinner" />}
    </>
  );
};

ChatMessageContainer.propTypes = {
  englishBot: PropTypes.bool,
  messages: PropTypes.array,
  countriesList: PropTypes.array,
  lexError: PropTypes.bool,
  startDateTravel: PropTypes.string,
  endDateTravel: PropTypes.string,
  agentTypingFlag: PropTypes.bool,
  intentLang: PropTypes.string,
  slotToElicit: PropTypes.string,
  runningAWSConnect: PropTypes.bool,
  sessionID: PropTypes.string,
  sendToLex: PropTypes.func,
  setMaxLength: PropTypes.func,
  handleMessage: PropTypes.func,
  onEndDateSelectHandler: PropTypes.func,
  handeLanguageChangeMessage: PropTypes.func,
  handlerLiveAgentTrigger: PropTypes.func,
  ReconnectToAwsConnect: PropTypes.func,
  handleWrapUpStepOneYes: PropTypes.func,
  setDefaultTextContent: PropTypes.func,
  handleWrapUpStepOneNo: PropTypes.func,
  onStartDateSelectHandler: PropTypes.func,
  handeButtonLinkMessage: PropTypes.func,
  handleLanguageChange: PropTypes.func,
  handleWrapupMessage: PropTypes.func,
  setIntentLang: PropTypes.func,
  setWebFormType: PropTypes.func,
  handlePopupRunningCase: PropTypes.func,
  setShowModal: PropTypes.func,
  closeEvent: PropTypes.func,
  setIsCancelProcess: PropTypes.func,
  handlerPageIntent: PropTypes.func,
  askingForWaitingTime: PropTypes.func,
  setServiceFormTrigger: PropTypes.func,
  setGeneralFormTrigger: PropTypes.func,
  formInProcessTrigger: PropTypes.func,
  spinnerHandler: PropTypes.func,
  setAutoCompleteFlag: PropTypes.func,
  setShowAutoComplete: PropTypes.func,
  setShowRegisterCustomerButton: PropTypes.func,
  dropDownValues: PropTypes.array,
  uploadDocuments: PropTypes.func,
  attachmentLoader: PropTypes.bool,
  cancelUploadDocuments: PropTypes.func,
  handleEncryptedDataNeeded: PropTypes.func,
  setNewConciergeCustomer: PropTypes.func,
  setExistingConciergeCustomer: PropTypes.func,
  lexSuccessHandler: PropTypes.func,
  lexFailedHandler: PropTypes.func,
  handlePostLexToken: PropTypes.func,
  handlePostLexCatch: PropTypes.func,
  handleEnglishBot: PropTypes.func,
  state: PropTypes.object,
};
export default ChatMessageContainer;
