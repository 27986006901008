/* eslint-disable max-lines */
import { AREA_CODE, DEBIT_CARD_REQUIRED_TEXT, DIRECT_DEPOSITE_RADIO_TEXT } from '../constants';

export const clearContext = (leadGenerationContext) => {
  if (leadGenerationContext.flowTypeETB !== 'EtbFlow') {
    leadGenerationContext.setAccessToken('');
    leadGenerationContext.setProductType('');
    leadGenerationContext.setProductName('');
  }
  leadGenerationContext.setIsLogin(false);
  leadGenerationContext.setUtmCampaign(null);
  leadGenerationContext.setUtmSource(null);
  leadGenerationContext.setUtmMedium(null);
  leadGenerationContext.setUtmContent(null);
  leadGenerationContext.setUtmTerm(null);
  leadGenerationContext.setFirstName('');
  leadGenerationContext.setCurrentPage('');
  leadGenerationContext.setProductData({});
  leadGenerationContext.setAct22DocID(null);
  leadGenerationContext.setAct22DocUploadStatus(null);
  leadGenerationContext.setFormAnswer({});
  leadGenerationContext.setIdUploadStatus(null);
  leadGenerationContext.setAddressProofUploadStatus(null);
  leadGenerationContext.setOpenProductQuestionModal(false);
  leadGenerationContext.setConsentValues({});
  leadGenerationContext.setLinkedConsentClickedValues({});
  leadGenerationContext.setSubmitDisabled(true);
  leadGenerationContext.setconsentTimeStamps({});
  leadGenerationContext.setEmploymentInformation({});
  leadGenerationContext.setEmploymentInformationErrors({});
  leadGenerationContext.setOpenInactivityModal(false);
  leadGenerationContext.setOpenSessionTimeoutModal(false);
  leadGenerationContext.setIsAllLinksClicked(false);
  leadGenerationContext.setApplicationId(null);
  leadGenerationContext.setContactInformation({
    areaCode: AREA_CODE,
    email: '',
  });
  leadGenerationContext.setContactInformationErrors({});
  leadGenerationContext.setPersonalInformation({
    areaCode: AREA_CODE,
    altAreaCode: AREA_CODE,
    dob_day: 'DD',
    dob_month: 'MMM',
    dob_year: 'YYYY',
    ssn: '',
    unverifiedIdAddressVouched: false,
    vouchedIDExpiryDate: null,
  });
  leadGenerationContext.setPersonalInformationErrors({});
  leadGenerationContext.setAddressInformation({
    sameMailingAddress: true,
    country: 'PR',
    state: 'N/A',
    permanentCountry: 'PR',
    permanentState: 'N/A',
    defaultCountryBasedOnLocation: 'PR',
    vouchedAddrEdited: false,
    homeAddressUploadDocumentId: null,
  });
  leadGenerationContext.setAddressInformationErrors({});
  leadGenerationContext?.setIsFeatureScreenDirty(true);
  leadGenerationContext?.setDepositeCardDisburseFeature(DIRECT_DEPOSITE_RADIO_TEXT);
  leadGenerationContext?.setDebitCardDisburseFeature(DEBIT_CARD_REQUIRED_TEXT);
  leadGenerationContext?.setExcessTransferFeature(DIRECT_DEPOSITE_RADIO_TEXT);
  leadGenerationContext?.setExcessAmt({ excessAmtVal: '' });
  leadGenerationContext?.setExcessAmtError({ excessAmtVal: '' });
  leadGenerationContext.setItems([0]);
  leadGenerationContext.setItemsAnswer({});
  leadGenerationContext.setComplianceItems([0]);
  leadGenerationContext.setComplianceItemsAnswer({});
  leadGenerationContext.setComplianceData({});
  leadGenerationContext.setIsScreenOneDirty(true);
  leadGenerationContext.setIsScreenTwoDirty(true);
  leadGenerationContext.setIsScreenThreeDirty(true);
  leadGenerationContext.setIsScreenFourDirty(true);
  leadGenerationContext.setIsScreenFiveDirty(true);
  leadGenerationContext.setIsFundingScreenDirty(true);
  leadGenerationContext.setIsCommercialProductUIDirty(false);
  leadGenerationContext.setFundData({
    accountTransferOrCheck: true,
    anotherBankAccount: false,
    dgiAccountNumber: '',
    debitCard: false,
    orientalAccount: true,
    expiryMonth: 'MMM',
    expiryYear: 'YYYY',
  });
  leadGenerationContext.setFundDataErrors({});
  leadGenerationContext.setStatementUploadStatus(null);
  leadGenerationContext.setStatementDocumentId(null);
  leadGenerationContext.setBankDetails(null);
  leadGenerationContext.setDownloadAllDisabled(true);
  leadGenerationContext.setCrossCheckResponse({});

  // Comercial UI
  leadGenerationContext.setBusinessContactInformation({
    firstName: '',
    lastName: '',
    email: '',
    areaCode: AREA_CODE,
    phone: '',
    businessType: '',
    relationWithCompany: '',
    stateDeptRegistry: '',
    corporateTitle: '',
    other: '',
    businessLegalName: '',
  });

  leadGenerationContext.setBusinessContactInformationErrors({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    businessLegalName: '',
    businessType: '',
    relationWithCompany: '',
    stateDeptRegistry: '',
    corporateTitle: '',
    other: '',
  });

  leadGenerationContext.setIsBusinessContactInfoDirty(true);
  leadGenerationContext.setIsBusinessComplianceDirty(true);
  leadGenerationContext.setIsBusinessSuriDirty(true);
  leadGenerationContext.setIsBusinessAdditionalInfoDirty(true);
  leadGenerationContext.setBusinessAdditionalInfo({
    noOfEmployees: '',
    netWorth: '',
    annualSales: '',
    website: '',
    businessTaxId: '',
    timeInBusiness: '',
    creditAmount: '',
    creditRequestReason: '',
    collateralCredit: '',
    specifyCollateral: '',
    industryType: '',
  });

  leadGenerationContext.setBusinessAdditionalInfoErrors({
    noOfEmployees: '',
    netWorth: '',
    annualSales: '',
    website: '',
    businessTaxId: '',
    timeInBusiness: '',
    creditAmount: '',
    creditRequestReason: '',
    collateralCredit: '',
    specifyCollateral: '',
    industryType: '',
  });
  leadGenerationContext.setBusinessHomeaddress({
    country: 'PR',
    state: 'N/A',
    permanentCountry: 'PR',
    permanentState: 'N/A',
    defaultCountryBasedOnLocation: 'PR',
    vouchedAddrEdited: false,
    homeAddressUploadDocumentId: null,
  });
  leadGenerationContext.setVouchedData({});
  leadGenerationContext.setVouchedUploadCount(0);
  leadGenerationContext.setBusinessHomeaddressErrors({});

  leadGenerationContext.setSignerSavedData([]);

  // SURI
  leadGenerationContext.setIsCorrectSuriId(true);
  leadGenerationContext.setUpdatedSuri(null);
  leadGenerationContext.setNewSuriDetails({});
  leadGenerationContext.setIsCheckedSuri(true);
  leadGenerationContext.setIsActiveSuri(false);
  leadGenerationContext.setIsActiveSuriNo(false);
  leadGenerationContext.setSuriResponse({});
  leadGenerationContext.setRetrySuriData({
    incorrectSuriAttempt: 0,
    activeSuriId: true,
    suriSearch: '',
  });
  leadGenerationContext.setSuriSaveFlag({
    editFlag: false,
    checkboxFlag: false,
    remap: false,
  });
  leadGenerationContext.setEditableAddressError({
    businessAddress: '',
    businessOwnerAddress: '',
  });
  leadGenerationContext.setSavedSuriDocs([]);
  leadGenerationContext.setSavedBusinessDocs([]);
  leadGenerationContext.setSavedChecklistDocs([]);
  leadGenerationContext.setRetrySuriDataErrors({
    suriSearch: '',
  });

  leadGenerationContext.setval({
    businessAddressEdit: '',
    businessOwnerAddressEdit: '',
  });
  leadGenerationContext.setAddressSuriDocID(null);
  leadGenerationContext.setAddressSuriDocUploadStatus(null);
  leadGenerationContext.setBusinessUploadError({});
  leadGenerationContext.setOwnerAddressSuriDocUploadStatus(null);
  leadGenerationContext.setOwnerAddressSuriDocID(null);

  leadGenerationContext.setIsCheckedCompliance(true);
  leadGenerationContext.setComplianceVerifiedFirst(false);
  leadGenerationContext.setComplianceVerifiedSecond(false);

  leadGenerationContext.setIsCheckedBankruptcy(true);
  leadGenerationContext.setIsBusinessBankruptcy(false);
  leadGenerationContext.setIsBusinessBankruptcyNo(false);

  leadGenerationContext.setBusinessOwnerDetails({});
  leadGenerationContext.setBusinessOwnerDetailsError({});
  leadGenerationContext.setIsBulk(false);
  leadGenerationContext.setIsFast(false);
  leadGenerationContext.setIsGamb(false);
  leadGenerationContext.setIsAtm(false);
  leadGenerationContext.setDgiEmploymentStatus({
    empStatus: '',
    empYears: '',
    employer: '',
    previousEmployer: '',
    schoolName: '',
    sourceOfCurrentIncome: '',
    annualIncome: '',
    occupation: '',
  });
  leadGenerationContext.setTrustedContactInformation({
    fullName: '',
    dob: '',
    phone: '',
    address1: '',
    country: '',
    state: '',
    city: '',
    zipCode: '',
  });

  leadGenerationContext.setDgiFinanceInfo({
    annualIncome: '',
    netWorth: '',
    liquidNetWorth: '',
    taxBracket: '',
    investmentKnowledge: '',
    experienceInEquities: '',
    experienceInFixedIncome: '',
    experienceInMutualFunds: '',
    riskTolerance: '',
    investmentObjective: '',
    assetTimeHorizon: '',
    liquidityNeeds: '',
  });
  leadGenerationContext.setDgiFinanceInfoErrors({});
  leadGenerationContext.setIsAct(false);

  leadGenerationContext.setBusinessAccountInfo({
    ssn: '',
    ownerPercentage: '',
    dob: '',
    unverifiedIdAddressVouched: false,
    vouchedIDExpiryDate: null,
    dob_day: 'DD',
    dob_month: 'MMM',
    dob_year: 'YYYY',
  });

  leadGenerationContext.setAtmDocID(null);
  leadGenerationContext.setAtmDocUploadStatus(null);
  leadGenerationContext.setBusinessAccountInfoError({});
  leadGenerationContext.setIsHeading(false);
  leadGenerationContext.setServiceDate({
    isbulkSelected: '',
    isfastSelected: '',
    isgambSelected: '',
    isactSelected: '',
    isatmSelected: '',
  });
  // edelivery
  leadGenerationContext.setUserIds({});
  leadGenerationContext.setUserIdsErrors({});
  leadGenerationContext.setSelectedUserType('existingUser');
  leadGenerationContext.setEdeliveryInfo({});
  leadGenerationContext.setCodes({});
  leadGenerationContext.setCodesErrors({});
  leadGenerationContext.setIsEdeliveryScreenDirty(true);
  leadGenerationContext.setOnlineUserToggle(true);
  leadGenerationContext.setMsgModal(true);
  leadGenerationContext.setUploadGlobalStatus({
    atm: '',
    act: '',
    businessAddress: '',
    businessOwnerAddress: '',
  });
  leadGenerationContext.setUploadGlobalFiles({
    atm: '',
    act: '',
    businessAddress: '',
    businessOwnerAddress: '',
  });
  leadGenerationContext.setUploadGlobalDocId({
    atm: '',
    act: '',
    businessAddress: '',
    businessOwnerAddress: '',
  });
  leadGenerationContext.setGlobalProgress({
    act: 0,
    atm: 0,
    businessAddress: 0,
    businessOwnerAddress: 0,
  });
  leadGenerationContext.setBusinessAddress({});
  leadGenerationContext.setBusinessOwnerAddress({});

  leadGenerationContext.setBusinessAddUploadTemp({
    uploadFile: '',
    uploadStatus: '',
    uploadDocId: '',
    Progress: '',
  });
  leadGenerationContext.setBusinessOwnerAddUploadTemp({
    uploadFile: '',
    uploadStatus: '',
    uploadDocId: '',
    Progress: '',
  });
  leadGenerationContext.setModalMessageGlobal({
    messageTitle: '',
    messageSubTitle: '',
    typeName: '',
  });
  leadGenerationContext.setIsErrorGlobal(false);
  leadGenerationContext.setChecklistErrGlobal({ size: '', type: '' });
  leadGenerationContext.setOpenModalGlobal(false);

  leadGenerationContext.setCancelBAupload({});
  leadGenerationContext.setCancelBOAupload({});
  leadGenerationContext.setEditableAddress({
    businessAddress: '',
    businessOwnerAddress: '',
    isBAEdited: false,
    isBOEdited: false,
  });
  leadGenerationContext.setBusinessAddUploadTemp({
    uploadFile: '',
    uploadStatus: '',
    uploadDocId: '',
    Progress: '',
  });
  leadGenerationContext.setBusinessOwnerAddUploadTemp({
    uploadFile: '',
    uploadStatus: '',
    uploadDocId: '',
    Progress: '',
  });
  leadGenerationContext.setIsChked(false);
  leadGenerationContext.setInviFlowValues({
    areaCode: AREA_CODE,
    phone: '',
    selectCorporateTitle: '',
    email: '',
    corporateTitle: '',
  });
  leadGenerationContext.setQrCodeVal({
    productName: '',
    productCat: '',
    selectLang: '',
    custName: '',
    salesAdvisor: '',
    emailAdd: '',
  });
  leadGenerationContext.setQrCodeValError({
    productCat: '',
    selectLang: '',
    custName: '',
    salesAdvisor: '',
    emailAdd: '',
  });
  // leadGenerationContext.setIsQR(false);
  leadGenerationContext.setInviFlowValuesError({
    areaCode: '',
    phone: '',
    selectCorporateTitle: '',
    email: '',
    corporateTitle: '',
  });
  leadGenerationContext.setUploadGlobalStatus({
    atm: '',
    act: '',
    businessAddress: '',
    businessOwnerAddress: '',
  });
  leadGenerationContext.setUploadGlobalFiles({
    atm: '',
    act: '',
    businessAddress: '',
    businessOwnerAddress: '',
  });

  leadGenerationContext.setUploadGlobalDocId({
    atm: '',
    act: '',
    businessAddress: '',
    businessOwnerAddress: '',
  });
  leadGenerationContext.setGlobalProgress({
    act: 0,
    atm: 0,
    businessAddress: 0,
    businessOwnerAddress: 0,
  });
  leadGenerationContext.setModalMessageGlobal({
    messageTitle: '',
    messageSubTitle: '',
    typeName: '',
  });
  leadGenerationContext.setBusinessLegalNameInvi('');
  leadGenerationContext.setCancelActupload({});
  leadGenerationContext.setCancelAtmupload({});
  leadGenerationContext.setSignerCheck(false);
  leadGenerationContext.setOwnership(false);
  leadGenerationContext.setIsSuccessRespFound(false);
  leadGenerationContext.setInternationalWiretransferError(false);
  leadGenerationContext.setEnterCountry('');
  leadGenerationContext.setCountryList([]);
  leadGenerationContext.setReceivedCountryData([]);
  leadGenerationContext.setCountries([]);
  leadGenerationContext.setCancelAct22upload({});
  leadGenerationContext.setOrganization('');
  leadGenerationContext.setIsModalSave(false);
  leadGenerationContext.setSaveLaterVal([]);
  leadGenerationContext.setContinueModal(false);

  leadGenerationContext.setSavedDataFlow(false);
  leadGenerationContext.setUserSavedPage('');
  leadGenerationContext.setSavedItemsAnswer({});
  leadGenerationContext.setSavedFormAnswer({});
  leadGenerationContext.setAutomatedAccDetails({});
  // save app data
  leadGenerationContext.setIsApplicationSaved(false);
  leadGenerationContext.setSavedDataFlow(false);
  leadGenerationContext.setIsValidatingVouch(false);
  leadGenerationContext.setIsDuplicate(false);
  // ETB login
  leadGenerationContext.setLightAuthToken(null);
  leadGenerationContext.setErrorMessage('');
  leadGenerationContext.setDetailAcc([]);
  leadGenerationContext.setEmailList([]);
  leadGenerationContext.setPhoneList([]);
  leadGenerationContext.setInitialCallFlag(false);
  leadGenerationContext.setOtpToken('');
  leadGenerationContext.setLoginAttemptCounter(0);
  leadGenerationContext.setCustomerName('');
  leadGenerationContext.setCustomerNumber('');
  leadGenerationContext.setContactInfoDetails({});
  leadGenerationContext.setCustomerGuid('');
  leadGenerationContext.setCustomerType('');
  leadGenerationContext.setLanguageETBflow('EN');
  leadGenerationContext.setCustomerAppId('');
  leadGenerationContext.setSessionCallFlag(false);
  leadGenerationContext.setSessionCallFlagConsent(false);

  // ETB SCREEN ReceiveCode
  leadGenerationContext.setCustomerVerifyToken(null);
  leadGenerationContext.setOtpDataEmail('');
  leadGenerationContext.setDeliveryMedium('');
  leadGenerationContext.setState({
    accountNumber: '',
    deviceId: '',
    email: '',
    error: false,
    mobileNumber: '',
    mobileNumberTxt: '',
    prefferedOption: '',
    ssn: '',
  });
  leadGenerationContext.setCustomerPhone('');
  leadGenerationContext.setReceiveCodeState({});
  // ETB SCREEN  EnterOtp
  leadGenerationContext.setLoginDetails({
    phone: '',
    areaCode: '787',
    email: '',
    addresses: {},
    flag: true,
  });
  leadGenerationContext.setUserName('Guest');
  leadGenerationContext.setEtbLogin({
    ssn: '',
    dob: '',
  });
  leadGenerationContext.setLoadingDetails(false);
  leadGenerationContext.setInitialPayLoading(false);
  leadGenerationContext.setAttemptLeftInOptionSelect(0);
  leadGenerationContext.setIsEtbAddressDirty(true);
  leadGenerationContext.setIsNewAddress(false);
  leadGenerationContext.setSelectedAddress({});
  leadGenerationContext.setLoginDetailsErrors({});
  leadGenerationContext.setCallLookupApi(false);
  leadGenerationContext.setCallLookupApiHome(false);
  leadGenerationContext.setCallLookupApiEmp(false);
  leadGenerationContext.setCallLookupApiHomeSelfEmp(false);
  leadGenerationContext.setLoginHomeAddress({
    country: 'PR',
    state: 'N/A',
    permanentCountry: 'PR',
    permanentState: 'N/A',
    defaultCountryBasedOnLocation: 'PR',
  });
  leadGenerationContext.setLoginHomeAddressErrors({});
  leadGenerationContext.setAccNoListForOrientalAccount([]);
  leadGenerationContext.setPlaidDetails({ flowType: '', status: '' });
  leadGenerationContext.setPlaidAccNoLinked([]);
  leadGenerationContext.setPlaidSuccessDetails({ accId: '' });
  leadGenerationContext.setSelectedOrientalAccountProgress({});
  leadGenerationContext.setPlaidLinkTokenObj('');
  leadGenerationContext.setCallPlaidLink(false);
  leadGenerationContext.setPlaidRetry(0);
  leadGenerationContext.setMicroPlaidPopUp({
    snackbar: false,
    modal: false,
  });
  leadGenerationContext.setPlaidStatementMandatoryError(false);
  // leadGenerationContext.setMsgPopUp(false);

  leadGenerationContext.setInterestPaymentMethod((prevState) => ({
    ...prevState,
    typeOfAccount: null,
    accountNumber: null,
    routingNumber: null,
  }));
  leadGenerationContext.setInterestPaymentMethodErr({});
  leadGenerationContext.setCdFlowOffers({
    depositAmount: '',
    minDepositAmount: 1000,
    offers: {},
  });
  leadGenerationContext.setCdFlowOffersErr({
    depositAmount: '',
    offers: '',
  });
  leadGenerationContext.setDormantAcntNo('');
};
